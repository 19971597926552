import React, { Component } from 'react';
import cookie from "js-cookie";
import axios from "axios";
import chatClient, { doChatLoginForCustomer } from "../chatClient";
import { getUser } from "../selectors";
import { setUser, fetchRooms } from "../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { API_BASE_URL } from "../config";

class NewRoom extends Component {
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.state = {
			"phone": ""
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange (e) {
  	  this.setState({[e.target.name]: e.target.value});
  	}

	onSubmit = async function (e) {
		var self = this;
		e.preventDefault();
		cookie.remove("chat.user");
		cookie.remove("chat.user", { path: "" });
		var token = await axios.post(API_BASE_URL + "/customers/token", { phone: this.state.phone }, { withCredentials: true } );
		var inElevenHours = new Date(new Date().getTime() + 11 * 60 * 60 * 1000);
		cookie.set("chat.user", token.data.user, { expires: inElevenHours });
		cookie.set("user.jwt", token.data.token, { exipres: inElevenHours });
		await doChatLoginForCustomer(token.data.token);
		chatClient.emit("create_chat_room", { token: token.data.token }, function (err, room) {
			if (err) {
				alert("Room creation failed");
				console.error(err);
				return;
			}
			self.props.setUser();
			self.props.fetchRooms();
			self.props.history.push("/agent/chat");
		});
	}

	render() {

		const authenticated = this.props.user;

		// if (authenticated) {
		// 	return this.props.history.push("/agent/chat");
		// }

		return (
			<div style={{ paddingTop: 25 }}>
				<h1>New Room Page</h1>

				<form onSubmit={this.onSubmit}>
					<input type="text" className="form-control" placeholder="Enter phone number" name="phone" value={this.state.phone}
						onChange={this.handleChange}
					 />
					<button className="btn btn-default" type="submit">Create</button>
				</form>

			</div>
		)		
	}
}


const mapStateToProps = state => {
	return {
		user: getUser(state)
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ setUser, fetchRooms }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NewRoom);
