import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { getSelectedRoom, getUser, getRoomsLoadedFromAllRoomsTab } from '../selectors';
import { fetchAllRooms, openRoomFromAllRoomsTab } from '../actions';
import { bindActionCreators } from 'redux';
import store from '../store';
import Axios from 'axios';
import { API_BASE_URL } from '../config';

window.toaster = toast;

const WrappedTable = function(props) {
  return <ReactTable {...props} />;
};

class AllRooms extends Component {
  state = {
    data: [],
    pages: -1,
    loading: true,
    pageSize: 20,
    page: 0,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  openRoom = async function(event, room) {
    event.preventDefault();
    try {
      // var firstMessageIndex = store.getState().messages.items.findIndex((msg) => msg.room === room._id);
      // var firstMessage = store.getState().messages.items[firstMessageIndex];
      // window.messageToScrollTo = firstMessage._id;
      toast.success(
        `Room ${room.participants[0].phoneNumber} has been added to claimed tab in chat menu!`,
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );
      this.props.openRoomFromAllRoomsTab(room);
      this.props.history.push('/agent/chat');
    } catch (err) {
      console.error(err);
    }
  };

  dataFetcher = function(state, instance) {
    let self = this;
    self.setState({ loading: true });
    console.log('onFetchData');
    self.props
      .fetchAllRooms({
        page: state.page,
        pageSize: state.pageSize,
        sorted: state.sorted,
        filtered: state.filtered,
      })
      .then((res) => {
        self.setState({
          loading: false,
          data: res.data,
          pages: Math.ceil(res.count / state.pageSize),
        });
      })
      .catch((err) => {
        if (err.name === 'CHAT_CLIENT_DISCONNECTED' && state.loading) {
          setTimeout(function() {
            self.dataFetcher(state, instance);
          }, 1000);
        }
      });
  };
  render() {
    let self = this;

    const columns = [
      {
        id: 'roomName', // Required because our accessor is not a string
        Header: 'Room Name',
        accessor: (d) => d.participants[0].phoneNumber, // Custom value accessors!
        filterMethod: (filter, row) =>
          row[filter.id].startsWith(filter.value) || row[filter.id].endsWith(filter.value),
        Cell: (row) => {
          return (
            <div>
              <a href="#" onClick={(e) => this.openRoom(e, row.original)}>
                {row.value}
              </a>
            </div>
          );
        },
      },
      {
        id: 'resolved', // Required because our accessor is not a string
        Header: 'Resolved At',
        accessor: (d) => d.resolved, // Custom value accessors!
        filterable: false,
        Cell: (row) => {
          return <div>{row.value ? row.value.toLocaleString() : 'Not Resolved Yet'}</div>;
        },
      },
      {
        id: 'claimer', // Required because our accessor is not a string
        Header: 'Claimed By',
        accessor: (d) => d.agent, // Custom value accessors!
        filterable: false,
        Cell: (row) => {
          return <div>{row.value && (row.value.name || row.value.username)}</div>;
        },
      },
      {
        id: 'open',
        Header: 'Opened?',
        accessor: (d) => d,
        filterable: false,
        sortable: false,
        Cell: (row) => {
          return (
            <div>
              {this.props.openRooms.roomIds.indexOf(row.value._id) !== -1 && (
                <i className="fas fa-check" />
              )}
            </div>
          );
        },
      },
    ];

    const rooms = this.state.data;

    return (
      <div style={{ paddingTop: 25 }}>
        <h3>All Rooms</h3>
        <ToastContainer />
        <div style={{ paddingTop: 15 }} />
        <WrappedTable
          page={this.state.page}
          data={this.state.data}
          pages={this.state.pages}
          loading={this.state.loading}
          columns={columns}
          filterable={true}
          defaultSorted={[
            {
              id: 'resolved',
              desc: true,
            },
          ]}
          manual
          onFetchData={(state, instance) => this.dataFetcher(state, instance)}
          pageSize={this.state.pageSize}
          onPageSizeChange={(newSize) => {
            this.setState({ pageSize: newSize });
          }}
          onPageChange={(newPage) => {
            if (newPage < 0 || newPage >= this.state.pages) return;
            this.setState({
              page: newPage,
            });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedRoom: getSelectedRoom(state),
    user: getUser(state),
    openRooms: getRoomsLoadedFromAllRoomsTab(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchAllRooms, openRoomFromAllRoomsTab }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllRooms);
