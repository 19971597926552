import { USERS_ADD, USERS_UPDATE } from "../actions";

const initialState = {
    items: [],
    userIds: []
}

const users = (state = initialState, action) => {
     switch (action.type) {
        case USERS_ADD:
            return state;
        case USERS_UPDATE:
            return state;
        default:
            return state;
    }
}

export default users;
