import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import thunk from 'redux-thunk';
import { reduxBatch }  from '@manaflair/redux-batch';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducers,
    composeEnhancers(
        reduxBatch,
        applyMiddleware(thunk),
        reduxBatch
    )
);

// TODO: Remove

window.reduxStore = store;

export default store;
