import React, { Component } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config';

export default class RiveEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { code: '', loading: true, updating: false };
  }

  async componentWillMount() {
    const { data: script } = await axios.get(`${API_BASE_URL}/rivescript`, {
      withCredentials: true,
    });
    if (script && script.code) {
      this.setState({ loading: false, code: script.code });
    } else {
      this.setState({ loading: false });
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  updateScript = async () => {
    this.setState({ updating: true });
    try {
      await axios.put(
        `${API_BASE_URL}/rivescript`,
        { code: this.state.code },
        { withCredentials: true },
      );
      window.toastContainer.success('Successfully updated the script');
    } catch (err) {
      window.toastContainer.error('Couldnt update the script');
    } finally {
      this.setState({ updating: false });
    }
  };
  onReloadBot = async () => {
    try {
      await axios.post(`${API_BASE_URL}/rivescript/reload`, {}, { withCredentials: true });
      window.toastContainer.success('Successfully reload the bot');
    } catch (err) {
      window.toastContainer.error('Couldnt reload the bot');
    }
  };
  onResetAllRoom = async () => {
    try {
      await axios.post(`${API_BASE_URL}/rivescript/resetall`, {}, { withCredentials: true });
      window.toastContainer.success('Successfully reset all room');
    } catch (err) {
      window.toastContainer.error('Couldnt reset all room');
    }
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="spinner-border m-4" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    }
    return (
      <div id="wrapper" className="m-4">
        <textarea
          className="form-control"
          rows="20"
          name="code"
          onChange={this.onChange}
          defaultValue={this.state.code}
        />
        <button
          className="btn btn-primary mt-3 mr-2"
          onClick={this.updateScript}
          disabled={this.state.updating}
        >
          {this.state.updating && (
            <div className="spinner-border spinner-border-sm mr-2" role="status" />
          )}
          Update
        </button>

        <button onClick={this.onReloadBot} className="btn btn-danger mt-3 mr-2">
          Reload bot
        </button>

        <button onClick={this.onResetAllRoom} className="btn btn-success mt-3 mr-2">
          Reset all room
        </button>
      </div>
    );
  }
}
