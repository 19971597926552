// // Initiate the connection to the server

import axios from "axios";
import isAuthenticated from "./utils/isAuthenticated";
import { API_BASE_URL, CHAT_SERVER_URL } from "./config";
import io from 'socket.io-client';
import { receiveMessage, setTypingText, updateRoom, subscribeToRoom, refreshRoomSubscriptions, addRoom } from "./actions";
import store from "./store";
import cookie from "js-cookie";


/** 
 * 
 * SocketIO
 * 
 */

const socketIO = window.socketIO = io(CHAT_SERVER_URL);

socketIO.on("pong", (latency) => {
	console.log("Chat Ping Latency: ", latency);
});

socketIO.on("authenticated", function () {
	console.log("socket authenticated");
});

socketIO.on("chat_msg", (msg) => {
	console.log(msg);
	let message = msg;
	store.dispatch(receiveMessage(message, true));
});

socketIO.on("typing_notification", (notification) => {
	let message = notification;
	if (store.getState().rooms.selectedRoom !== message.room) return;
	store.dispatch(setTypingText(notification));
});

setTimeout(function () {
	console.log("10 secs later");
	socketIO.on("connect", async () => {
		console.log("connected 2");
		// await onConnectHandler({});
	});
}, 10000);

socketIO.on("connect", async () => {
	console.log("connected");
	await onConnectHandler({});
	store.dispatch(refreshRoomSubscriptions());
});

socketIO.on("reconnect", async (attemptNumber) => {
	await onConnectHandler({});
	store.dispatch(refreshRoomSubscriptions());
});

socketIO.on("newChatRooms", function (room) {
	console.log("newChatRooms: ", room);
	var user = store.getState().user._id;
	if (!user) return;
	let _room = room.room;
	if (_room.createdAt) {
		_room.createdAt = new Date(_room.createdAt);
	}
	if (_room.updatedAt) {
		_room.updatedAt = new Date(_room.updatedAt);
	}
	if (_room.resolved) {
		_room.resolved = new Date(_room.resolved);
	}
	if (store.getState().rooms.roomIds.indexOf(_room._id) === -1) {
		store.dispatch(addRoom(_room));
	} else {
		store.dispatch(updateRoom(_room));
	}
	store.dispatch(subscribeToRoom(_room._id));
});

socketIO.on("updatedChatRooms", function (room) {
	var user = store.getState().user._id;
	if (!user) return;
	// if (room.claimed && room.room.agent._id !== user._id) {
		// removeRoom?
		let _room = room.room;
		if (_room.createdAt) {
			_room.createdAt = new Date(_room.createdAt);
		}
		if (_room.updatedAt) {
			_room.updatedAt = new Date(_room.updatedAt);
		}
		if (_room.resolved) {
			_room.resolved = new Date(_room.resolved);
		}
		store.dispatch(updateRoom(_room));
	// } else {
		store.dispatch(updateRoom(_room));
	// }
	store.dispatch(subscribeToRoom(_room._id));
});

/**
 * 
 * 
 * End SocketIO
 * 
 */



window.chatUid = null;


export function doChatLoginForCustomer(token) {
	return new Promise((resolve, reject) => {

		socketIO.emit("authenticate", token, function (err, res) {
			if (err) return reject(err);
			resolve();
		});

	});
}

export function doChatLoginForAgent(token) {
	return new Promise((resolve, reject) => {

		socketIO.emit("authenticate", token, function (err, res) {
			if (err) return reject(err);
			resolve();
		})

	});
}

export async function onConnectHandler(status) {
	console.log("stat", status);
	return new Promise(async function (resolve, reject) {
		if (!status.isAuthenticated) {
			try {

				var inElevenHours = new Date(new Date().getTime() + 11 * 60 * 60 * 1000);

				const user = store.getState().user._id;
				if (!user) {
					return resolve();
				}

				if (user) {

					const user = store.getState().user;
					if (user.isAgent) {
						var token = await axios.get(API_BASE_URL + "/agent/token", {
							withCredentials: true
						});
	
						if (token.data) {

							await doChatLoginForAgent(token.data);

							cookie.set("user.jwt", token.data, { exipres: inElevenHours });

							return resolve();
						}
					} else {
						var token = await axios.post(API_BASE_URL + "/customers/token", { phone: user.phoneNumber }, { withCredentials: true } );
						if (token.data) {
							await doChatLoginForCustomer(token.data.token);
							cookie.set("user.jwt", token.data.token, { exipres: inElevenHours });
							return resolve();
						}
					}
				}

			} catch (err) {
				console.error(err);
				return reject(err);
			}
		} else {
			// if socket is authenticated but web app session is not, we deauthenticate the socket as well
			const user = store.getState().user._id;
			if (!user) {
				socketIO.emit("deauthenticate", function (err, res) {
					window.location = window.location;
				});
				return resolve(socketIO);
			}
			console.log("onConnect alreadyAuthenticated");
			return resolve(socketIO);
		}
	});
}

export const socketDeauthenticator = function () {
	socketIO.emit("deauthenticate", function (err, res) {
		console.log(err, res);
		window.location = "/";
	});
}

export default socketIO;
