import { combineReducers } from "redux";
import rooms from "./rooms";
import messages from "./messages";
import typingIndicator from "./typingIndicator";
import user from "./user";
import users from "./users";

export default combineReducers({
    rooms,
    messages,
    typingIndicator,
    user,
    users
});
