import React from 'react';
import { Page, Text, Document, StyleSheet, Font, PDFViewer, View } from '@react-pdf/renderer';
import { Row, Col } from 'react-bootstrap';

Font.register('https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf', {
  family: 'Oswald',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
});

const LABELS = {
  caseNumber: 'Case Number',
  date: 'Date',
  regulation: 'Regulation',
  reason: 'Reason',
  address: 'Address',
  name: 'Name',
};

export default class NoSignageParking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      caseNumber: '',
      date: '',
      reason: '',
      address: '',
      name: '',
      regulation: '',
    };
  }
  render() {
    const {
      caseNumber = 'Case Number',
      date = 'Date',
      address = 'Address',
      name = 'Name',
      reason = 'Reason',
      regulation = 'Regulation',
    } = this.state;
    return (
      <div>
        <Row>
          {Object.keys(LABELS).map((labelKey) => (
            <Col key={labelKey} sm={6}>
              <div className="form-group">
                <label>{LABELS[labelKey]}</label>
                <input
                  value={this.state[labelKey]}
                  onChange={(e) => this.setState({ [labelKey]: e.target.value })}
                  placeholder={LABELS[labelKey]}
                  className="form-control"
                />
              </div>
            </Col>
          ))}
        </Row>
        <PDFViewer width="100%" height={1000}>
          <Document>
            <Page style={styles.body}>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <Text style={styles.text}>{caseNumber}</Text>
                <Text style={styles.text}>{date}</Text>
              </View>
              <Text style={styles.text}>To whom it may concern,</Text>
              <Text style={styles.text}>
                Thank you for taking into account my appeal. I am appealing on the basis that the
                alleged contravention did not occur because there were no clear signs informing me
                of the regulations, as required by {regulation}.
              </Text>
              <Text style={styles.text}>
                Though the ticket is the result of allegedly {reason}, I argue that the lack of
                signs on {address} failed to inform me of the regulations.
              </Text>
              <Text style={styles.text}>
                As the signage (or lack thereof) failed to inform me of what was required, no
                offence has been committed. I believe that the court should exercise fairness in
                cancelling a ticket that, according to the guidance, is perfectly justified to be
                cancelled
              </Text>
              <Text style={styles.text}>
                I will of course endeavor to avoid this again, but I feel that the issue of a ticket
                is an unlawful action inconsistent with precedent. Please let me know if you have
                any further questions.
              </Text>
              <Text style={styles.text}>Thank you,</Text>
              <Text style={styles.text}>{name}</Text>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}
