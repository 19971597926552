import { 
    SET_TYPING_TEXT,
    CLEAR_TYPING_TEXT
} from "../actions";

const typingIndicator = (state = { 
    notification: null
 }, action) => {
    switch (action.type) {
        case SET_TYPING_TEXT:
            return Object.assign({}, state, {
                notification: action.notification
            });
        case CLEAR_TYPING_TEXT:
            return Object.assign({}, state, {
                notification: null
            });
        default:
            return state;
    }
}

export default typingIndicator;
