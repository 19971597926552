import { createSelector } from 'reselect';
import isAuthenticated from "../utils/isAuthenticated";
import _ from "lodash";

const _getRoomItems = state => state.rooms.items;
const _getRoomState = state => state.rooms;
const _getMessageItems = state => state.messages.items;
const _getSelectedRoom = state => state.rooms.selectedRoom;
const _getTypingState = state => state.typingIndicator;
const _getUser = state => state.user;
const _getPulsingRooms = state => state.rooms.pulsingRooms;

// TODO: Finish with openHistoryRooms
export const getRooms = createSelector(
    [_getRoomItems],
    (rooms) => {
        // If the user is an agent, return rooms that the user is assigned to
        // And filter out any rooms that have been opened from the all rooms / history tab
        // Also filter resolved rooms for agents
        // If the user is a customer, return the first room that they are a participant in
        // Finally, sort the rooms by newest first
        var authenticated = isAuthenticated();
		if (!authenticated) return [];
		if (authenticated.isAgent) {
			rooms = _.filter(rooms, (room) => (room.agent ? room.agent._id : null) === authenticated._id);
			// rooms = _.filter(rooms, (room) => this.openHistoryRooms.indexOf(room._id) === -1);
		} else {
			rooms = _.filter(rooms, (room) => (room.participants && 
				room.participants.findIndex( (participant) => participant._id === authenticated._id ) !== -1));
		}
		if (authenticated.isAgent) {
			rooms = _.filter(rooms, (room) => !room.resolved);
		}
		return rooms.sort((a, b) => b.createdAt - a.createdAt)
    }
)

export const getUnclaimedRooms = createSelector(
    [_getRoomItems],
    (rooms) => {
        // Return rooms that have not been claimed by any agent
        // And have not been opened from all rooms / history tab
        rooms = _.filter(rooms, (room) => (!room.agent));
		// rooms = _.filter(rooms, (room) => this.openHistoryRooms.indexOf(room._id) === -1);
		return rooms.sort((a, b) => b.createdAt - a.createdAt);
    }
)

export const getSelectedRoom = createSelector(
    [_getRoomState, _getSelectedRoom],
    (roomState, selectedRoom) => {
        if (!roomState.selectedRoom) return null;
        var roomIndex = roomState.items.findIndex((room) => room._id === roomState.selectedRoom);
		if (roomIndex === -1) return null;
		return roomState.items[roomIndex];
    }
)

export const getOpenedHistoryRooms = createSelector(
    [_getRoomState],
    (roomState) => {
        var rooms = _.filter(roomState.items, (room) => roomState.openHistoryRooms.indexOf(room._id) !== -1);
        console.log("opened from history: ", rooms);
        console.log(roomState.items.length);
		return {
            rooms: rooms.sort((a, b) => b.createdAt - a.createdAt),
            roomIds: _.map(rooms, (room) => room._id)
        };
    }
)

export const getOpenedClaimedRooms = createSelector(
    [_getRoomState, _getUser],
    (roomState, user) => {
		var rooms = _.filter(roomState.items, (room) => (room.agent && 
			room.agent._id !== user._id &&
			roomState.openClaimedRooms.indexOf(room._id) !== -1
		));
		return rooms.sort((a, b) => b.createdAt - a.createdAt);
    }
)

export const getRoomsLoadedFromAllRoomsTab = createSelector(
    [_getRoomState],
    (roomState) => {
        var rooms = _.filter(roomState.items, (room) => roomState.roomsLoadedFromAllRoomsTab.indexOf(room._id) !== -1);
		return {
            rooms: rooms.sort((a, b) => b.createdAt - a.createdAt),
            roomIds: _.map(rooms, (room) => room._id)
        };
    }
)

export const getOpenClaimedRoomIds = createSelector(
    [_getRoomState],
    (roomState) => {
        return roomState.openClaimedRooms;
    }
)

export const getMessagesForActiveRoom = createSelector(
    [_getRoomState, _getMessageItems],
    (roomState, messages) => {
        var authenticated = isAuthenticated();
		if (authenticated.isAgent) {
            // if (roomState.roomsLoadedFromAllRoomsTab.indexOf(roomState.selectedRoom) !== -1) {
                if (roomState.historyRoomMappings[roomState.selectedRoom]) {
                    var allRoomsForCustomer = roomState.historyRoomMappings[roomState.selectedRoom];
                    messages = _.filter(messages, (msg) => allRoomsForCustomer.indexOf(msg.room) !== -1);
                }
            // } 
            else {
				messages = _.filter(messages, (msg) => msg.room === roomState.selectedRoom);
			}
		}
		return messages.sort((a, b) => a.createdAt - b.createdAt);
    }
)

window.getMessagesForActiveRoom = getMessagesForActiveRoom;

export const getTypingText = createSelector(
    [_getSelectedRoom, _getTypingState],
    (selectedRoom, typingState) => {
        if (!typingState.notification) return null;
        if (selectedRoom === typingState.notification.room) {
            return typingState.notification.text;
        }
        return null;
    }
)

export const getUser = createSelector(
    [_getUser],
    (user) => {
        if (!user._id) return false;
        return user;
    }
)

export const getPulsingRooms = createSelector(
    [_getPulsingRooms],
    (pulsingRooms) => {
        return pulsingRooms;
    }
)
