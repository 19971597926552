import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { getUser } from "../selectors";
import { connect } from "react-redux";
import { setLoggedOut } from "../actions";
import { bindActionCreators } from "redux";


const ReqUser = ComposedComponent => {
	class FetchUser extends Component {
		constructor(props) {
			super(props);
			this.state = {
				loading: true,
				error: false,
				user: {},
				isRedirect: false
			};

			this.checkLogin.bind(this);
		}

		componentDidMount() {
			this.checkLogin();
		}

		checkLogin = async function () {
			const self = this;

			try {
				const profile = this.props.user;
				if (profile) {
					self.setState({
						user: profile,
						loading: false
					});
				} else {
					self.setState({
						isRedirect: true
					});
				}
			} catch (err) {
				self.setState({
					isRedirect: true
				});
			}

			// self.check = setTimeout(function () {
			// 	// TODO: Implement login check
			// 	self.setState({
			// 		loading: false,
			// 		isRedirect: true
			// 	});
			// }, 5000);
		}

		componentWillUnmount() {
			// clearInterval(this.check);
		}

		render() {

			const { loading, error, user, isRedirect } = this.state;

				if (isRedirect) {
					return <Redirect to="/login" />
				}

				if (!isRedirect && loading) {
					return (
						<h4>Loading...</h4>
					)
				}

				return (
					<ComposedComponent {...this.props} profile={user} />
				)
		}
	}


	const mapStateToProps = state => {
		return {
			user: getUser(state)
		}
	}

	const mapDispatchToProps = dispatch => {
		return bindActionCreators({ setLoggedOut }, dispatch);
	};

	return connect(mapStateToProps, mapDispatchToProps)(FetchUser);
}

export default ReqUser;
