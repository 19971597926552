import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { bindActionCreators } from "redux";
import { fetchRooms, openRoomFromHistory, fetchResolvedRooms } from "../actions";
import { connect } from "react-redux";
import { getRooms, getUnclaimedRooms, getSelectedRoom, getOpenedHistoryRooms } from "../selectors";


window.toaster = toast;

const WrappedTable = function (props) {
  return <ReactTable {...props} />
};

class History extends Component {

  state = {
    data: [],
    pages: -1,
    loading: true,
    pageSize: 20,
    page: 0
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    
  }

  openRoom = async function (event, room) {
    event.preventDefault();
    try {
      // var firstMessageIndex = window.ChatMessagesStore.roomMessages.findIndex((msg) => msg.room === room._id);
      // var firstMessage = window.ChatMessagesStore.roomMessages[firstMessageIndex];
      // if (firstMessage) {
        // window.messageToScrollTo = firstMessage._id;
      // }
      toast.success(`Room ${room.participants[0].phoneNumber} has been added to history tab in chat menu!`, {
        position: toast.POSITION.TOP_CENTER
      });
      this.props.openRoomFromHistory(room);
      this.props.history.push('/agent/chat');
    } catch (err) {
      console.error(err);
    }
  }

  render() {

    let self = this;

    const columns = [
      {
          id: "roomName", // Required because our accessor is not a string
          Header: "Room Name",
          accessor: d => d.participants[0].phoneNumber, // Custom value accessors!
          filterMethod: (filter, row) => 
              row[filter.id].startsWith(filter.value) ||
              row[filter.id].endsWith(filter.value),
          Cell: row => {
            return <div><a href="#" onClick={(e) => this.openRoom(e, row.original)}>{row.value}</a></div>
          }
      },
      {
          id: "resolved", // Required because our accessor is not a string
          Header: "Resolved At",
          accessor: d => d.resolved, // Custom value accessors!
          filterable: false,
          Cell: row => {
              return <div>{row.value.toLocaleString()}</div>
          }
      },
      {
        id: "open",
        Header: "Opened?",
        accessor: d => d,
        filterable: false,
        sortable: false,
        Cell: row => {
          return<div>{self.props.openedRoomsFromHistory.roomIds.indexOf(row.value._id) !== -1 && <i className="fas fa-check" />}</div>
        }
      }
    ];

    const rooms = this.state.data;

    return (
      <div style={{ paddingTop: 25 }}>
        <h3>Resolved Rooms</h3>
        <ToastContainer />
        <div style={{ paddingTop: 15 }} />
        <WrappedTable
              page={this.state.page}
              data={this.state.data}
              pages={this.state.pages}
              loading={this.state.loading}
              columns={columns}
              filterable={true}
              defaultSorted={[
                  {
                      id: "resolved",
                      desc: true
                  }
              ]}
              manual
              onFetchData={(state, instance) => {
                  let self = this;
                  self.setState({ loading: true });
                  self.props.fetchResolvedRooms({
                      page: state.page,
                      pageSize: state.pageSize,
                      sorted: state.sorted,
                      filtered: state.filtered
                    }).then((res) => {
                      self.setState({
                          loading: false,
                          data: res.data,
                          pages: Math.ceil(res.count / state.pageSize)
                      });
                  })
              }}
              pageSize={this.state.pageSize}
              onPageSizeChange={(newSize) => {
                this.setState({ pageSize: newSize });
              }}
              onPageChange={(newPage) => {
                if (newPage < 0 || newPage >= this.state.pages) return;
                this.setState({
                  page: newPage
                });
              }}
          />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ openRoomFromHistory, fetchResolvedRooms }, dispatch);
};

const mapStateToProps = state => {
  return {
    rooms: getRooms(state),
    unclaimedRooms: getUnclaimedRooms(state),
    selectedRoom: getSelectedRoom(state),
    isFetching: state.rooms.isFetching,
    openedRoomsFromHistory: getOpenedHistoryRooms(state)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(History);

