import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from 'axios';
import { API_BASE_URL } from './config';
import { Modal, Form, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

window.toaster = toast;

export default class ShortcutsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shortcuts: [],
      createModal: false,
      name: '',
      text: '',
    };
  }
  async componentDidMount() {
    const result = await axios.get(`${API_BASE_URL}/shortcuts/list`);
    try {
      if (result.data.length > 0) {
        this.setState({
          shortcuts: result.data.filter((shortcut) => shortcut.text && shortcut.name),
        });
      }
    } catch (err) {
      return toast(err.message);
    }
  }
  onCreateShortcut = async (e) => {
    e.preventDefault();
    try {
      const result = await axios.post(`${API_BASE_URL}/shortcuts/new`, {
        name: this.state.name,
        text: this.state.text,
      });
      if (result.data._id) {
        this.setState((prevState) => ({
          createModal: false,
          shortcuts: [...prevState.shortcuts, result.data],
        }));
        toast('Created');
      }
    } catch (err) {
      toast(err.message);
    }
  };
  onDeleteShortcut = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/shortcuts/${id}`);
      // Remove shortcuts from state
      this.setState((prevState) => ({
        shortcuts: prevState.shortcuts.filter((shortcut) => shortcut._id !== id),
      }));
    } catch (err) {
      toast(err.message);
    }
  };
  renderEditable = (cellInfo) => {
    return (
      <div
        className="textarea w-100"
        style={{ backgroundColor: '#fafafa' }}
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => {
          const shortcuts = [...this.state.shortcuts];
          shortcuts[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          this.setState({ shortcuts });
          this.onUpdateShortcut(shortcuts[cellInfo.index]);
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.shortcuts[cellInfo.index][cellInfo.column.id],
        }}
      />
    );
  };
  onUpdateShortcut = async (updatedShortcut) => {
    try {
      axios.put(`${API_BASE_URL}/shortcuts/${updatedShortcut._id}`, {
        name: updatedShortcut.name,
        text: updatedShortcut.text,
      });
    } catch (err) {
      toast(err.message);
    }
  };
  render() {
    const { shortcuts, createModal } = this.state;
    return (
      <div className="pt-4">
        <ToastContainer />
        <h1>Shortcuts</h1>
        <hr />
        <button
          onClick={() => this.setState({ createModal: true })}
          className="btn btn-primary mb-4"
        >
          New Shortcut
        </button>

        <Modal show={createModal} onHide={() => this.setState({ createModal: false })}>
          <Modal.Header closeButton>
            <Modal.Title>New Shortcut</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.onCreateShortcut}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  onChange={(e) => this.setState({ name: e.target.value })}
                  placeholder="Shortcut Name"
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Text</Form.Label>
                <Form.Control
                  onChange={(e) => this.setState({ text: e.target.value })}
                  placeholder="Shortcut Text"
                />
              </Form.Group>
              <Button variant="primary" type="submit" block>
                Create
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <div className="mb-4">
          <ReactTable
            footer={null}
            data={shortcuts}
            columns={[
              {
                id: 'name',
                Header: 'Name',
                accessor: 'name',
              },
              {
                id: 'text',
                Header: 'Text',
                accessor: 'text',
                Cell: this.renderEditable,
              },
              {
                sortable: false,
                id: 'delete',
                Cell: (row) => (
                  <button
                    onClick={() => this.onDeleteShortcut(row.original._id)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                ),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}
