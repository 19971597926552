import React, { Component } from 'react';
import { getUser } from "../selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";


class ReqUsername extends Component {
    constructor(props) {
        super(props);
        // this.props.user;
    }

    render() {
        return (
            <div></div>
        )
    }
}

const mapStateToProps = state => {
	return {
		user: getUser(state)
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReqUsername);
