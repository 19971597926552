import React from 'react';
import { connect } from 'react-redux';
import { getSelectedRoom, getPulsingRooms } from './selectors';
import { setSelectedRoom } from './actions';
import { bindActionCreators } from 'redux';

class ChatRoom extends React.PureComponent {
  render() {
    const authenticated = this.props.authenticated;

    const room = this.props.room;

    const type = this.props.type;

    // If room has no messages, and has not been resolved or claimed by an agent, don't render
    // it in the sidebar room list
    if (room.msg_count <= 0 && authenticated.isAgent) {
      if (!room.resolved) {
        return null;
      }
      if (!room.agent) {
        return null;
      }
    }

    const isSelectedClass =
      this.props.selectedRoom && this.props.selectedRoom._id === room._id ? 'active' : '';

    var isPulsing = false;

    if (this.props.selectedRoom && this.props.selectedRoom._id !== room._id) {
      if (this.props.pulsingRooms.indexOf(room._id) !== -1) {
        isPulsing = true;
      }
    }

    let hasOutStandingMessage = false;
    if (room && room.lastMessage) {
      // Map thru participants to see from the message comign from them
      const filter = room.participants.filter(
        (participant) => participant._id === room.lastMessage.sender,
      );
      if (filter.length > 0) {
        hasOutStandingMessage = true;
      }
    }

    return (
      <li
        className={'contact ' + isSelectedClass}
        key={type + '_' + room._id}
        onClick={() => this.props.setSelectedRoom(room._id)}
      >
        <div className="wrap">
          <span className="contact-status online" />
          <img src="/avatar.png" alt="" />
          <div className="meta">
            <p className="name">
              {room.participants[0] && room.participants[0].phoneNumber}{' '}
              <a className="badge p-1">
                {room.participants[0] && room.participants[0].tags && room.participants[0].tags[0]}
              </a>
            </p>
            <p className="preview" />
          </div>
        </div>
        {isPulsing ||
          (hasOutStandingMessage && (
            <div className="ring-container">
              <div className="ringContainerChild">
                <div className="ringring" />
                <div className="circle" />
              </div>
            </div>
          ))}
      </li>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedRoom: getSelectedRoom(state),
    pulsingRooms: getPulsingRooms(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setSelectedRoom }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatRoom);
