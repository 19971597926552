import React, { Component } from "react";
import Script from 'react-load-script';
import axios from "axios";
import { API_BASE_URL } from "./config";
import cookie from "js-cookie";

class PlaidPage extends Component {
    constructor(props) {
        super(props);
        this.plaidBtn = React.createRef();
        this.plaidHandler = null;
    }

    state = {
        scriptLoaded: false,
        scriptError: false,
        plaidAuthenticated: false,
        chargingInProgress: false,
        plaidLinkInProgress: false,
    }

    componentDidMount = async function () {
        try {
            let authToken = cookie.get("user.jwt");
            let plaidAuthenticated = await axios.post(API_BASE_URL + "/user/check_plaid_status",
                {},
                { withCredentials: false, headers: {"Authorization": `Bearer ${authToken}`} }
            );
            let authenticated = plaidAuthenticated.data.authenticated;
            this.setState({
                plaidAuthenticated: authenticated
            });
            console.log(plaidAuthenticated);
            console.warn(authenticated);
        } catch (err) {
            
        }

    }

    handleScriptCreate = () => {
        this.setState({ scriptLoaded: false });
    }

    handleScriptError = () => {
        this.setState({ scriptError: true });
    }

    handleScriptLoad = () => {

        let self = this;

        if (!window.Plaid) return;

        this.plaidHandler = window.Plaid.create({
            env: process.env.REACT_APP_DNP_PLAID_ENV || 'sandbox',
            clientName: 'DoNotPay',
            key: process.env.REACT_APP_DNP_PLAID_PUBLIC_KEY,
            product: ['auth'],
            selectAccount: true,
            onSuccess: async function (public_token, metadata) {
                // Send the public_token and account ID to your app server.
                console.log('public_token: ' + public_token);
                console.log("metadata: ", metadata);
                console.log('account ID: ' + metadata.account_id);

                self.setState({
                    plaidLinkInProgress: true
                })

                let authToken = cookie.get("user.jwt");

                try {
                    let res = await axios.post(API_BASE_URL + "/user/authenticate_plaid",
                        { public_token, account_id: metadata.account_id },
                        { withCredentials: false, headers: {"Authorization": `Bearer ${authToken}`} }
                    );
                    self.setState({
                        plaidLinkInProgress: false
                    });
                    if (res.data.success) {
                        self.setState({
                            plaidAuthenticated: true
                        });
                    }
                    console.log(res);
                } catch (err) {
                    console.log(err);
                }

            },
            onExit: function (err, metadata) {
                self.setState({
                    plaidLinkInProgress: false
                });
                // The user exited the Link flow.
                if (err != null) {
                    // The user encountered a Plaid API error prior to exiting.
                }
            },
        });

        this.setState({ scriptLoaded: true });
    }

    onClick = (e) => {
        e.preventDefault();

        this.plaidHandler && this.plaidHandler.open();

    }

    onChargeClick = async (e) => {
        e.preventDefault();

        try {
            this.setState({
                chargingInProgress: true
            });
            let res = await axios.post(API_BASE_URL + "/user/create_payment", {}, { withCredentials: true });
            console.log(res);
            this.setState({
                chargingInProgress: false
            });
        } catch (err) {
            console.log(err);
        }
    }

    scriptStatus = () => {
        if (this.state.scriptError) {
            return <h4>Error Loading Plaid</h4>
        }
        if (!this.state.scriptLoaded) {
            return <h4>Loading Plaid...</h4>
        }
        return null;
    }

    render() {

        let buttonText = "Open Plaid Link";

        const { scriptLoaded, plaidAuthenticated, chargingInProgress, plaidLinkInProgress } = this.state;

        if (plaidAuthenticated) {
            buttonText += " Again";
        }

        return (
            <div style={{ paddingTop: 25 }}>
                <h2>Plaid Test</h2>

                <hr />

                <Script
                    url="https://cdn.plaid.com/link/v2/stable/link-initialize.js"
                    onCreate={this.handleScriptCreate.bind(this)}
                    onError={this.handleScriptError.bind(this)}
                    onLoad={this.handleScriptLoad.bind(this)}
                />

                {this.scriptStatus()}

                {plaidAuthenticated && <h5 style={{ paddingBottom: 10 }}>You have authenticated using Plaid</h5>}

                {scriptLoaded && (
                    <div>
                        <button
                            className="btn btn-info"
                            ref={this.plaidBtn}
                            onClick={this.onClick}
                            disabled={plaidLinkInProgress}
                        >
                            {buttonText}
                        </button>
                    </div>
                )}

                {plaidAuthenticated && (
                    <div>
                        <button
                            style={{ marginTop: 10 }}
                            className="btn btn-info"
                            onClick={this.onChargeClick}
                            disabled={chargingInProgress}
                        >
                            Create Test Charge
                        </button>
                    </div>
                )}


            </div>
        )
    }
}

export default PlaidPage;
