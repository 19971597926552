import React from 'react';
import { Page, Text, Document, StyleSheet, Font, PDFViewer, View } from '@react-pdf/renderer';
import { Row, Col } from 'react-bootstrap';

Font.register('https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf', {
  family: 'Oswald',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 47,
  },
  text: {
    marginVertical: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
  },
});

const LABELS = {
  date: 'Date',
  partyName: 'Party name',
  partyAddress: 'Party Address',
  oweAmount: 'Owe Amount',
  oweDate: 'Owe Date',
  contactBeforeDate: 'Contact Before Date',
  payableTo: 'Payable to',
  phoneNumber: 'Contact Phone Number',
  contactName: 'Contact Name',
};

export default class DemandLetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
      partyName: '',
      partyAddress: '',
      oweAmount: '',
      oweDate: '',
      contactBeforeDate: '',
      payableTo: '',
      phoneNumber: '',
      contactName: '',
    };
  }
  render() {
    const {
      date,
      partyName,
      partyAddress,
      oweAmount,
      oweDate,
      contactBeforeDate,
      payableTo,
      phoneNumber,
      contactName,
    } = this.state;
    return (
      <div>
        <Row>
          {Object.keys(LABELS).map((labelKey) => (
            <Col key={labelKey} sm={6}>
              <div className="form-group">
                <label>{LABELS[labelKey]}</label>
                <input
                  value={this.state[labelKey]}
                  onChange={(e) => this.setState({ [labelKey]: e.target.value })}
                  placeholder={LABELS[labelKey]}
                  className="form-control"
                />
              </div>
            </Col>
          ))}
        </Row>
        <PDFViewer width="100%" height={1000}>
          <Document>
            <Page style={styles.body}>
              <Text style={styles.text}>{date}</Text>
              <Text style={{ ...styles.text, marginVertical: 0 }}>{partyName}</Text>
              <Text style={{ ...styles.text, marginVertical: 0 }}>{partyAddress}</Text>
              <Text style={styles.text}>
                Re: Demand for Payment: Final Demand Letter Before Legal Action
              </Text>
              <Text style={styles.text}>Dear {partyName}:</Text>
              <Text style={styles.text}>
                Please be advised that you owe me the sum of ${oweAmount} because since {oweDate},
                you have not paid your sublet rent.
              </Text>
              <Text style={styles.text}>
                This will be your only chance to settle this matter before I file suit against you
                in Small Claims Court. I am agreeable to a lump sum payment, or to a payment plan.
                Please contact me on or before {contactBeforeDate} for purposes of settling this
                matter. If I do not hear from you on or before {contactBeforeDate}, I will file a
                lawsuit against you without further notice. It is in your best interest to settle
                this matter before a lawsuit is filed. If a judgment is obtained against you, it
                will negatively affect your ability to get credit, you will be ordered to pay court
                costs, and you will incur interest at a rate of 10% per annum.
              </Text>
              <Text style={styles.text}>
                Based on the foregoing, I expect payment in the amount of ${oweAmount} made payable
                to me, {payableTo} no later than {contactBeforeDate}. (I can be reached at:
                Telephone: {phoneNumber}.) If you decide to ignore this demand for payment, I will
                further pursue all of its legal remedies without further notice to you. This letter
                serves as evidence that I have attempted to resolve this matter informally.
              </Text>
              <Text style={styles.text}>Sincerely,</Text>
              <Text style={{ ...styles.text, marginVertical: 0 }}>
                {contactName}
              </Text>
              <Text style={{ ...styles.text, marginVertical: 0 }}>
                {payableTo}
              </Text>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}
