import { 
    ADD_ROOM, SELECT_DEFAULT_ROOM, 
    FINISH_LOADING_ROOMS, OPEN_ROOM_FROM_HISTORY,
    SET_SELECTED_ROOM, UPDATE_ROOM,
    OPEN_CLAIMED_ROOM, CLOSE_OPEN_CLAIMED_ROOM,
    CLOSE_OPEN_HISTORY_ROOM, ADD_HISTORY_ROOM_MAPPING,
    ADD_PULSING_SIGN_TO_ROOM, ADD_SUBSCRIBED_ROOM,
    ADD_ROOM_TO_ALL_ROOMS_TAB,
    FINISH_LOADING_FROM_ALL_ROOMS_TAB
} from "../actions";

const rooms = (state = { 
    isFetching: true, items: [], selectedRoom: null, roomIds: [],
    openHistoryRooms: [], openClaimedRooms: [], historyRoomMappings: {},
    pulsingRooms: [], roomsWithMessages: [], subscribedRooms: [],
    roomsLoadedFromAllRoomsTab: [], loadingFromAllRoomsTab: false
 }, action) => {
    switch (action.type) {
        case ADD_ROOM:
            // Room is already in state
            if (state.roomIds.indexOf(action.room._id) !== -1) return state;
            return Object.assign({}, state, {
                items: [...state.items, action.room],
                roomIds: Array.from(new Set([...state.roomIds, action.room._id])),
                roomsWithMessages: action.room.msg_count > 0 ? [action.room._id, ...state.roomsWithMessages] : state.roomsWithMessages
            })
        case SELECT_DEFAULT_ROOM:
            if (state.selectedRoom) return state;
            for (var i = 0; i < state.items.length; i++) {
                if (!state.items[i].resolved) {
                    return Object.assign({}, state, {
                        selectedRoom: state.items[i]._id
                    });
                }
            }
            return state;
        case FINISH_LOADING_ROOMS:
            return Object.assign({}, state, {
                isFetching: false
            });
        case OPEN_ROOM_FROM_HISTORY:
            if (state.openHistoryRooms.indexOf(action.room._id) === -1) {
                return Object.assign({}, state, {
                    openHistoryRooms: [...state.openHistoryRooms, action.room._id]
                });
            }
            return state;
        case OPEN_CLAIMED_ROOM:
            if (state.openClaimedRooms.indexOf(action.room._id) === -1) {
                return Object.assign({}, state, {
                    openClaimedRooms: [...state.openClaimedRooms, action.room._id]
                });
            }
            return state;
        case ADD_ROOM_TO_ALL_ROOMS_TAB:
            if (state.roomsLoadedFromAllRoomsTab.indexOf(action.room._id) === -1) {
                return Object.assign({}, state, {
                    roomsLoadedFromAllRoomsTab: [...state.roomsLoadedFromAllRoomsTab, action.room._id],
                    loadingFromAllRoomsTab: true
                });
            }
            return state;
        case SET_SELECTED_ROOM:
            return Object.assign({}, state, {
                selectedRoom: action.roomId,
                pulsingRooms: state.pulsingRooms.filter(roomId => roomId !== action.roomId)
            });
        case UPDATE_ROOM:
            // Add or Update
            if (state.roomIds.indexOf(action.room._id) === -1) {
                return Object.assign({}, state, {
                    items: state.items.map(room => {
                        if (room._id === action.room._id) {
                            return {
                                ...action.room
                            }
                        }
                        return room;
                    }),
                    roomIds: Array.from(new Set([...state.roomIds, action.room._id])),
                    roomsWithMessages: action.room.msg_count > 0 ?
                        [action.room._id, ...state.roomsWithMessages] : state.roomsWithMessages
                })
            }
            return Object.assign({}, state, {
                items: state.items.map(room => {
                    if (room._id === action.room._id) {
                        return {
                            ...action.room
                        }
                    }
                    return room;
                }),
                roomsWithMessages: action.room.msg_count > 0 ? 
                    [action.room._id, ...state.roomsWithMessages] : state.roomsWithMessages
            });
        case CLOSE_OPEN_CLAIMED_ROOM:
            return Object.assign({}, state, {
                openClaimedRooms: state.openClaimedRooms.filter(roomId => roomId !== action.roomId),
                selectedRoom: state.selectedRoom === action.roomId ? null : state.selectedRoom
            });
        case CLOSE_OPEN_HISTORY_ROOM:
            return Object.assign({}, state, {
                openHistoryRooms: state.openHistoryRooms.filter(roomId => roomId !== action.roomId),
                selectedRoom: state.selectedRoom === action.roomId ? null : state.selectedRoom
            });
        case ADD_HISTORY_ROOM_MAPPING:
            let roomId = action.roomId;
            return Object.assign({}, state, {
                historyRoomMappings: {
                    ...state.historyRoomMappings,
                    [roomId]: action.relatedRoomIds
                }
            });
        case ADD_PULSING_SIGN_TO_ROOM:
            return Object.assign({}, state, {
                pulsingRooms: [...state.pulsingRooms, action.roomId]
            });
        case ADD_SUBSCRIBED_ROOM:
            if (state.subscribedRooms.indexOf(action.roomId) !== -1) {
                return state;
            }
            return Object.assign({}, state, {
                subscribedRooms: [...state.subscribedRooms, action.roomId]
            });
        case FINISH_LOADING_FROM_ALL_ROOMS_TAB:
            return Object.assign({}, state, {
                loadingFromAllRoomsTab: false
            });
        default:
            return state;
    }
}

export default rooms;
