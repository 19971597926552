import React, { Component } from 'react';
import chatClient from './chatClient';
import _ from 'lodash';
import './Chat.scss';
import ChatRoomList from './ChatRoomList';
import ChatMessageList from './ChatMessageList';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { getSelectedRoom, getUser, getOpenClaimedRoomIds } from './selectors';
import {
  setSelectedRoom,
  sendMessage,
  claimRoom,
  transferRoom,
  resolveRoom,
  closeOpenHistoryRoom,
  closeOpenClaimedRoom,
  loadFullRoomHistory,
} from './actions';
import { bindActionCreators } from 'redux';
import { Modal, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import { API_BASE_URL } from './config';
import Form from 'react-bootstrap/FormControl';
import { toast } from 'react-toastify';

window.stopTypingTimeout = undefined;

class ChatWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      selectedRoom: null,
      toggled: false,
      customerTypingText: null,
      shortcutModal: false,
      shortcutModalVariable: false,
      shortcuts: [],
      shortcutText: '',
      object: {},
    };
    // this.chatRoom = null;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.chatRoom = null;
    this.attachRoomListener = this.attachRoomListener.bind(this);
    this.mobileSidebarToggler = this.mobileSidebarToggler.bind(this);
    this.onToggleSidebar = this.onToggleSidebar.bind(this);
    this.onRoomClaim = this.onRoomClaim.bind(this);
    this.onRoomTransfer = this.onRoomTransfer.bind(this);
    this.onRoomResolution = this.onRoomResolution.bind(this);
    this.isTyping = false;
    this.emitTypingNotification = this.emitTypingNotification.bind(this);
    this.onRoomArchive = this.onRoomArchive.bind(this);
    this.emitTypingNotification = _.throttle(this.emitTypingNotification, 500);
    this.onLoadFullHistory = this.onLoadFullHistory.bind(this);
    this.stopTypingTimer = setTimeout(() => 1, 0);
  }

  componentDidMount = () => {
    let self = this;
    this.attachRoomListener();
    this.onFetchShortcuts();
  };
  onFetchShortcuts = async () => {
    const result = await axios.get(`${API_BASE_URL}/shortcuts/list`);
    try {
      if (result.data.length > 0) {
        this.setState({
          shortcuts: result.data.filter((shortcut) => shortcut.text && shortcut.name),
        });
      }
    } catch (err) {
      return alert(err.message);
    }
  };

  attachRoomListener() {}

  emitTypingNotification = (text) => {
    chatClient.emit('userIsTyping', { text, typing: true, room: this.props.selectedRoom._id });
    // If user hasn't typed in 1 second, we send a "stopped typing" event
    clearTimeout(this.stopTypingTimer);
    this.stopTypingTimer = setTimeout(this.emitStopTypingNotification, 1000);
  };

  emitStopTypingNotification = () => {
    return new Promise((resolve, reject) => {
      chatClient.emit('userIsTyping', { typing: false, room: this.props.selectedRoom._id });
      return resolve();
    });
  };

  componentWillUnmount() {
    // chatClient.unsubscribe("chatRoom");
  }

  onChange = (e) => {
    this.setState({
      text: e.target.value,
    });
  };

  onKeyDown = (e) => {
    this.setState({
      text: e.target.value,
    });
    if (this.isTyping === false) {
    }
    if (e.which === 13) {
      // return this.handleSubmit();
    } else {
      if (e.target.value.trim() === '') {
        return this.emitStopTypingNotification();
      }
      this.emitTypingNotification(e.target.value);
    }
  };

  sendMessage(text) {
    let message = {
      text,
      room: this.props.selectedRoom._id,
    };
    return this.props.sendMessage(message);
  }

  handleSubmit = async function(e) {
    e.preventDefault();

    let self = this;

    let text = this.state.text;

    if (text.trim().length === 0) return;

    try {
      await this.emitStopTypingNotification();
      await this.sendMessage(text);
      this.setState({
        text: '',
      });
      this.onLoadFullHistory();
    } catch (err) {
      console.error('Error sending message: ', err);
    }
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onToggleSidebar() {
    this.setState({
      toggled: !this.state.toggled,
    });
  }

  mobileSidebarToggler() {
    return (
      <button
        onClick={this.onToggleSidebar}
        className="navbar-toggler my-auto chatMobileToggler d-inline-block float-left"
        type="button"
      >
        <i className="fas fa-bars" />
      </button>
    );
  }

  onRoomClaim() {
    this.props
      .claimRoom(this.props.selectedRoom._id)
      .then(function() {})
      .catch(function(err) {
        alert('Failed to claim room');
      });
  }

  onRoomTransfer() {
    this.props
      .transferRoom(this.props.selectedRoom._id)
      .then(function() {})
      .catch(function(err) {
        alert('Failed to release room');
      });
  }

  async onRoomResolution(tipAmount) {
    // Call API to create a charge
    try {
      if (tipAmount) {
        await axios.post(
          `${API_BASE_URL}/rooms/${this.props.selectedRoom._id}/charge`,
          {
            amount: tipAmount,
          },
          { withCredentials: true },
        );
      }

      this.props
        .resolveRoom(this.props.selectedRoom._id)
        .then(function() {})
        .catch(function(err) {
          throw new Error('Failed to resolve room');
        });
      this.onHideResolveModal();
    } catch (err) {
      alert(err.message);
    }
  }

  onRoomArchive() {
    this.props.closeOpenHistoryRoom(this.props.selectedRoom._id);
    this.props.closeOpenClaimedRoom(this.props.selectedRoom._id);
  }

  claimRoomButton() {
    return (
      <button
        onClick={this.onRoomClaim}
        className="navbar-toggler my-auto claimRoomButton ml-auto mr-4 d-inline-block"
        type="button"
      >
        Claim Room
      </button>
    );
  }

  transferRoomButton() {
    return (
      <button
        onClick={this.onRoomTransfer}
        className="navbar-toggler my-auto claimRoomButton ml-auto mr-4 d-inline-block"
        type="button"
      >
        <i className="fas fa-share-square" />
        &nbsp;Transfer
      </button>
    );
  }

  onLoadFullHistory() {
    this.props.loadFullRoomHistory(this.props.selectedRoom);
  }

  loadFullHistoryButton() {
    return (
      <button
        onClick={this.onLoadFullHistory}
        className="navbar-toggler my-auto claimRoomButton ml-auto mr-4 d-inline-block"
        type="button"
      >
        <i className="fas fa-history" />
        &nbsp;Load History
      </button>
    );
  }

  resolveRoomButton() {
    return (
      <button
        onClick={() => this.setState({ askTipModal: true })}
        className="navbar-toggler my-auto claimRoomButton ml-auto mr-4 d-inline-block"
        type="button"
      >
        <i className="fas fa-check" />
        &nbsp;Resolve
      </button>
    );
  }

  archiveRoomButton() {
    return (
      <button
        onClick={this.onRoomArchive}
        className="navbar-toggler my-auto claimRoomButton ml-auto mr-4 d-inline-block"
        type="button"
      >
        <i className="fas fa-archive" />
        &nbsp;Archive
      </button>
    );
  }

  onRenderVariable = () => {
    var matches = (this.state.shortcutText.match(/\{(.*?)\}/g) || []).map((str) =>
      str.slice(1, -1),
    );
    let array = [];
    let newString = this.state.shortcutText;
    matches.map((matchedStr) => {
      const maxStr = newString.indexOf(`{${matchedStr}}`);
      array.push(<span key={maxStr + 111}>{newString.substr(0, maxStr)}</span>);
      array.push(
        <input
          key={maxStr + 992}
          value={this.state.object[matchedStr] || ''}
          onChange={(e) => {
            this.setState({ object: { ...this.state.object, [matchedStr]: e.target.value } });
          }}
          className="mb-3 shortcut-input"
          placeholder={matchedStr}
        />,
      );
      newString = newString.substr(maxStr + matchedStr.length + 2, newString.length);
    });
    const disabled =
      Object.keys(this.state.object).reduce((accum, key) => {
        if (this.state.object[key]) {
          return (accum = false);
        }
        return (accum = true);
      }, true) || Object.keys(this.state.object).length !== matches.length;

    return (
      <div>
        <div className="mb-3">{array}</div>
        <button
          onClick={async (e) => {
            let fullString = this.state.shortcutText;

            matches.map((matchedStr) => {
              fullString = fullString.replace(
                `{${matchedStr}}`,
                this.state.object[matchedStr] || '',
              );
            });
            this.setState({
              shortcutModal: false,
              shortcutModalVariable: false,
              object: {},
            });

            let text = fullString;

            if (text.trim().length === 0) return;

            try {
              await this.emitStopTypingNotification();
              await this.sendMessage(text);
              this.setState({
                text: '',
              });
              this.onLoadFullHistory();
            } catch (err) {
              console.error('Error sending message: ', err);
            }
          }}
          className="btn btn-primary btn-block"
          disabled={disabled}
        >
          Submit
        </button>
      </div>
    );
  };
  onUploadFiles = async (files) => {
    try {
      if (files.length === 0) {
        return;
      }
      this.setState({ dropzone_active: false });
      const formData = new FormData();
      formData.append('files', files[0]);
      const result = await axios.post(`${API_BASE_URL}/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (result.data) {
        await this.sendMessage(`082415pdf:::display=${files[0].name}:::link=${result.data}`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  onHideResolveModal = () => {
    this.setState({ askTipModal: false, tipAmount: 0 });
  };
  render() {
    let { messages, profile } = this.props;
    let { text, toggled, shortcutModal, shortcuts, shortcutModalVariable } = this.state;

    let authenticated = this.props.user;
    // if (!authenticated) {
    // 	this.props.history.push("/login");
    // 	return null;
    // }

    let user = authenticated;

    const username = authenticated
      ? authenticated.name || authenticated.username || authenticated.phoneNumber
      : null;

    var selectedRoom = this.props.selectedRoom;

    var showClaimRoomBtn = false;
    var showTransferBtn = false;
    var showArchiveBtn = false;
    var showHistoryBtn = false;
    var roomTitle = '';

    if (selectedRoom) {
      showClaimRoomBtn = authenticated.isAgent && !selectedRoom.agent && !selectedRoom.resolved;
      showTransferBtn =
        authenticated.isAgent &&
        (selectedRoom.agent && selectedRoom.agent._id === authenticated._id) &&
        !selectedRoom.resolved;
      if (authenticated.isAgent) {
        roomTitle = selectedRoom.resolved ? '(Resolved) ' : '';
        roomTitle += selectedRoom.participants[0] && selectedRoom.participants[0].phoneNumber;
        if (selectedRoom.agent) {
          roomTitle += ' [' + (selectedRoom.agent.name || selectedRoom.agent.username) + ']';
        }
        if (selectedRoom.resolved && selectedRoom.agent) {
          showArchiveBtn = true;
        }
        if (this.props.openClaimedRoomIds.indexOf(selectedRoom._id) !== -1) {
          showArchiveBtn = true;
        }
        showHistoryBtn = true;
      } else {
        roomTitle = selectedRoom.agent && (selectedRoom.agent.name || selectedRoom.agent.username);
      }
    }

    return (
      <div className={'ChatBox  ' + (toggled ? ' toggled' : '')} id="wrapper">
        <div id="chat-frame" className="d-flex">
          <div id="sidepanel">
            <div id="profile">
              <div className="wrap">
                <p>{username}</p>
                <div id="status-options">
                  <ul>
                    <li id="status-online" className="active">
                      <span className="status-circle" /> <p>Online</p>
                    </li>
                    <li id="status-away">
                      <span className="status-circle" /> <p>Away</p>
                    </li>
                    <li id="status-busy">
                      <span className="status-circle" /> <p>Busy</p>
                    </li>
                    <li id="status-offline">
                      <span className="status-circle" /> <p>Offline</p>
                    </li>
                  </ul>
                </div>
                <div id="expanded" />
              </div>
            </div>
            <ChatRoomList />
          </div>
          <div className="content" id={'page-content-wrapper'}>
            <div className="contact-profile d-flex">
              {this.mobileSidebarToggler()}
              {/* <img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" /> */}
              <p style={{ marginLeft: 25 }}>{roomTitle}</p>
              <div className="float-right ml-auto">
                {showClaimRoomBtn && this.claimRoomButton()}
                {showTransferBtn && this.transferRoomButton()}
                {showTransferBtn && this.resolveRoomButton()}
                {showArchiveBtn && this.archiveRoomButton()}
                {showHistoryBtn && this.loadFullHistoryButton()}
              </div>
            </div>

            <Dropzone
              key="onews"
              disableClick
              style={{ position: 'relative' }}
              onDrop={(files) => this.onUploadFiles(files)}
              onDragEnter={() => this.setState({ dropzone_active: true })}
              onDragLeave={() => this.setState({ dropzone_active: false })}
              ref={(node) => (this.dropzoneRef = node)}
              accept=".pdf"
            >
              {this.state.dropzone_active && (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 999,
                    bottom: 0,
                    left: 0,
                    padding: '2.5em 0',
                    background: 'rgba(0,0,0,0.5)',
                    textAlign: 'center',
                    color: '#fff',
                  }}
                >
                  <div style={{ margin: '100px' }}>
                    <h3>Drop to upload.</h3>
                  </div>
                </div>
              )}
            </Dropzone>
            <ChatMessageList profile={user} />

            <div className="message-input">
              <div className="wrap">
                <form
                  className="d-flex align-items-center justify-content-flex-start"
                  onSubmit={this.handleSubmit}
                  autoComplete="off"
                >
                  <div className="input-container">
                    <input
                      value={text}
                      name="text"
                      onChange={this.onKeyDown}
                      type="text"
                      autoComplete={'off'}
                      placeholder="Write your message..."
                    />
                    <div>
                      <i
                        onClick={() => this.setState({ shortcutModal: true })}
                        className="fas fa-directions shortcut"
                        aria-hidden="true"
                      />
                      <i
                        onClick={() => this.dropzoneRef.open()}
                        className="fa fa-paperclip attachment"
                        aria-hidden="true"
                      />
                    </div>
                  </div>

                  <button className="submit" type="submit">
                    <i className="fa fa-paper-plane" aria-hidden="true" />
                  </button>
                </form>
              </div>
            </div>

            <Modal show={shortcutModal} onHide={() => this.setState({ shortcutModal: false })}>
              <Modal.Header closeButton>
                <Modal.Title>Shortcuts</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ListGroup>
                  {shortcuts.map((shortcut) => (
                    <ListGroup.Item
                      onClick={() => {
                        var matches = (shortcut.text.match(/\{(.*?)\}/g) || []).map((str) =>
                          str.slice(1, -1),
                        );

                        if (matches.length > 0) {
                          return this.setState({
                            shortcutModal: false,
                            shortcutModalVariable: true,
                            shortcutText: shortcut.text,
                          });
                        }
                        this.setState({ shortcutModal: false, text: shortcut.text });
                      }}
                      key={shortcut._id}
                      action
                    >
                      <span className="badge">{shortcut.name}</span>
                      {shortcut.text}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Modal.Body>
            </Modal>

            <Modal
              size="lg"
              show={shortcutModalVariable}
              onHide={() =>
                this.setState({ shortcutModalVariable: false, object: {}, shortcutText: '' })
              }
            >
              <Modal.Header closeButton>
                <Modal.Title>Fill in your variable</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>{this.onRenderVariable()}</div>
              </Modal.Body>
            </Modal>

            <Modal
              size="lg"
              show={this.state.askTipModal}
              onHide={this.onHideResolveModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>Enter tip amount</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <form>
                    <div className="form-group">
                      <label>Amount to charge</label>
                      <input
                        type="number"
                        min="1"
                        step="0.01"
                        className="form-control"
                        placeholder="Enter amount in dollars"
                        value={this.state.tipAmount || ''}
                        onChange={(e) => this.setState({ tipAmount: e.target.value })}
                      />
                      <small id="emailHelp" className="form-text text-muted">
                        Amount is in dollars. Please double check to make sure the amount is
                        correct.
                      </small>
                    </div>
                  </form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button onClick={this.onHideResolveModal} className="btn btn-danger">
                  Cancel
                </button>
                <button onClick={() => this.onRoomResolution()} className="btn btn-secondary">
                  Skip tipping
                </button>
                <button
                  disabled={this.state.tipAmount < 0.5 || !this.state.tipAmount}
                  onClick={() => this.onRoomResolution(this.state.tipAmount)}
                  className="btn btn-primary"
                >
                  Charge and resolve
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedRoom: getSelectedRoom(state),
    user: getUser(state),
    openClaimedRoomIds: getOpenClaimedRoomIds(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSelectedRoom,
      sendMessage,
      claimRoom,
      transferRoom,
      resolveRoom,
      closeOpenHistoryRoom,
      closeOpenClaimedRoom,
      loadFullRoomHistory,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatWidget);
