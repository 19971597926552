import React, { Component } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config';
import chatClient from './chatClient';
import { bindActionCreators } from 'redux';
import { fetchRooms, updateRooms } from './actions';
import { connect } from 'react-redux';
import {
  getRooms,
  getUnclaimedRooms,
  getSelectedRoom,
  getOpenedHistoryRooms,
  getUser,
  getOpenedClaimedRooms,
  getRoomsLoadedFromAllRoomsTab,
} from './selectors';
import ChatRoom from './ChatRoom';
import { withRouter } from 'react-router';

class ChatRoomList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rooms: [],
      loading: true,
    };
    this.loadRoomList = this.loadRoomList.bind(this);
    this.userStatus = this.userStatus.bind(this);
  }

  componentDidMount() {
    this.roomFetchListener = chatClient.on("authenticated", this.loadRoomList);
    this.loadRoomList();
  }

  componentWillUnmount() {
    // remove event listener
    this.roomFetchListener.off();
  }

  loadRoomList = async function() {
    try {
      this.props.fetchRooms();
    } catch (err) {
      console.error(err);
    }
  };

  userStatus() {
    return ['online', 'busy', 'away', 'offline']; // for reference
  }

  render() {
    const authenticated = this.props.user;

    const rooms = this.state.rooms.length > 0 ? this.state.rooms : this.props.rooms;

    if (this.props.isFetching && rooms.length === 0) {
      return (
        <div id="contacts">
          <ul>
            <li>Loading...</li>
          </ul>
        </div>
      );
    }

    if (!authenticated) {
      this.props.history.push('/login');
      return null;
    }

    return (
      <div id="contacts">
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header" id="headingOne">
              <h2 className="mb-0">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <i className="fas fa-chevron-down" />
                  &nbsp;&nbsp;Your Rooms
                </button>
              </h2>
            </div>
            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
              <div className="card-body">
                {rooms.length < 1 ? (
                  <ul>
                    <li>
                      <h4>&nbsp;&nbsp;No Rooms Yet</h4>
                    </li>
                  </ul>
                ) : (
                  ''
                )}
                <ul>
                  {authenticated.isAgent &&
                    rooms.map((room) => {
                      return (
                        <ChatRoom
                          room={room}
                          type="your"
                          authenticated={authenticated}
                          key={`your-${room._id}`}
                        />
                      );
                    })}
                  {!authenticated.isAgent && rooms && rooms.length > 0 && (
                    <ChatRoom
                      room={rooms[0]}
                      type="your"
                      authenticated={authenticated}
                      key={`your-${rooms[0]._id}`}
                    />
                  )}
                </ul>
              </div>
            </div>
          </div>
          {this.props.unclaimedRooms && authenticated.isAgent && (
            <div className="card">
              <div className="card-header" id="headingTwo">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <i className="fas fa-chevron-down" />
                    &nbsp;&nbsp;Unclaimed Rooms
                  </button>
                </h2>
              </div>
              <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo">
                <div className="card-body">
                  <ul>
                    {this.props.unclaimedRooms &&
                      authenticated.isAgent &&
                      this.props.unclaimedRooms.map((room) => {
                        return (
                          <ChatRoom
                            room={room}
                            type="unclaimed"
                            authenticated={authenticated}
                            key={`unclaimed-${room._id}`}
                          />
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          )}

          {authenticated.isAgent && (
            <div className="card">
              <div className="card-header" id="headingOne">
                <h2 className="mb-0">
                  <button
                    className="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="true"
                    aria-controls="collapseSix"
                  >
                    <i className="fas fa-chevron-down" />
                    &nbsp;&nbsp;Loaded Rooms
                  </button>
                </h2>
              </div>
              <div id="collapseSix" className="collapse show" aria-labelledby="headingOne">
                <div className="card-body">
                  {this.props.roomsLoadedFromAllRoomsTab.roomIds.length < 1 ? (
                    <ul>
                      <li>
                        <h4>&nbsp;&nbsp;Go to all rooms tab to load rooms</h4>
                      </li>
                    </ul>
                  ) : (
                    ''
                  )}
                  <ul>
                    {this.props.roomsLoadedFromAllRoomsTab.rooms.map((room) => {
                      return (
                        <ChatRoom
                          room={room}
                          type="all-rooms-tab"
                          authenticated={authenticated}
                          key={`all-rooms-tab-${room._id}`}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchRooms, updateRooms }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    rooms: getRooms(state),
    unclaimedRooms: getUnclaimedRooms(state),
    selectedRoom: getSelectedRoom(state),
    isFetching: state.rooms.isFetching,
    // openedRoomsFromHistory: getOpenedHistoryRooms(state),
    user: getUser(state),
    openClaimedRooms: getOpenedClaimedRooms(state),
    roomsLoadedFromAllRoomsTab: getRoomsLoadedFromAllRoomsTab(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ChatRoomList));
