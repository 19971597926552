import { getMessagesForActiveRoom } from "../selectors";
export const SET_TYPING_TEXT = "SET_TYPING_TEXT";
export const CLEAR_TYPING_TEXT = "CLEAR_TYPING_TEXT";


function _setTypingText(notification) {
    return {
        type: SET_TYPING_TEXT,
        notification
    }
}

export function clearTypingText() {
    return {
        type: CLEAR_TYPING_TEXT
    }
}

export function setTypingText(notification) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        // If the last message was received within the past 5 seconds and 
        // the typing notification text starts with the last message text
        // remove the notification to prevent lingering indicators
        let roomMessages = getMessagesForActiveRoom(getState());
        var lastRoomMessage = roomMessages[roomMessages.length - 1];
        if (lastRoomMessage) {
            var now = new Date();
            if ((now - lastRoomMessage.createdAt) <=  5000 && lastRoomMessage.text.indexOf(notification.text) !== -1) {
                dispatch(clearTypingText());
                return resolve();
            }
        }
        dispatch(_setTypingText(notification));
        resolve();
    })
}
