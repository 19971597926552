import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { getUser } from './selectors';
import { fetchAllUsers } from './actions';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { API_BASE_URL } from './config';
import { Modal, Form } from 'react-bootstrap';
import { Creatable } from 'react-select';
import { DateTime } from 'luxon';

window.toaster = toast;

const WrappedTable = function(props) {
  return <ReactTable {...props} />;
};

class AllUsers extends Component {
  state = {
    data: [],
    pages: -1,
    loading: true,
    pageSize: 20,
    page: 0,
    notificationModal: false,
    token: '',
    from: '',
    message: '',
    user: null,
    tipsModal: false,
  };

  dataFetcher = function(state, instance) {
    let self = this;
    self.setState({ loading: true });
    self.props
      .fetchAllUsers({
        page: state.page,
        pageSize: state.pageSize,
        sorted: state.sorted,
        filtered: state.filtered,
      })
      .then((res) => {
        self.setState({
          loading: false,
          data: res.data,
          pages: Math.ceil(res.count / state.pageSize),
        });
      })
      .catch((err) => {
        if (err.name === 'CHAT_CLIENT_DISCONNECTED' && state.loading) {
          setTimeout(function() {
            self.dataFetcher(state, instance);
          }, 1000);
        }
      });
  };

  getUserName(user) {
    return user.phoneNumber || user.name || user.username;
  }
  onSubmitNotification = async (e) => {
    e.preventDefault();
    try {
      await axios.post(
        API_BASE_URL + '/notification',
        {
          token: this.state.token,
          message: this.state.message,
          from: this.state.from,
        },
        { withCredentials: true },
      );
      this.setState({ notificationModal: false, token: '', message: '', from: '' });
      toast.success('Message sent!');
    } catch (err) {
      toast.error(err.message);
    }
  };
  renderTagColumn = (cellInfo) => {
    return (
      <div>
        <Creatable
          value={cellInfo.value.tags.map((tag) => ({ label: tag, value: tag }))}
          onChange={(e) => {
            const data = [...this.state.data];
            const newTags = e.map((data) => data.value);
            data[cellInfo.index].tags = newTags;
            this.setState({ data });
            axios.put(
              `${API_BASE_URL}/user/${cellInfo.value._id}`,
              {
                tags: newTags,
              },
              {
                withCredentials: true,
              },
            );
          }}
          placeholder="Add a tag"
          isMulti
        />
      </div>
    );
  };
  onShowListOfTips = (user) => {
    this.setState({ user, tipsModal: true });
  };

  render() {
    if (!this.props.user.isAgent) return <h1 className="mt-4">Access Denied</h1>;

    const columns = [
      {
        id: 'userName', // Required because our accessor is not a string
        Header: 'User',
        accessor: (d) => d, // Custom value accessors!
        filterMethod: (filter, row) =>
          row[filter.id].startsWith(filter.value) || row[filter.id].endsWith(filter.value),
        Cell: (row) => {
          return <div>{this.getUserName(row.value)}</div>;
        },
      },
      {
        id: 'createdAt', // Required because our accessor is not a string
        Header: 'Created At',
        accessor: (d) => d.createdAt, // Custom value accessors!
        filterable: false,
        Cell: (row) => {
          return <div>{row.value.toLocaleString()}</div>;
        },
      },
      {
        id: 'payment',
        Header: 'Charge',
        accessor: (d) => d,
        filterable: false,
        sortable: true,
        Cell: (row) => {
          return row.value.plaidSetup ? (
            <StripeChargeHandler key={row.value._id} user={row.value} />
          ) : (
            'Plaid not setup'
          );
        },
      },
      {
        id: 'push_token',
        Header: 'Push Token',
        accessor: (d) => d,
        filterable: false,
        sortable: true,
        Cell: (row) => {
          return (
            <div>
              {row.value.pushNotificationToken ? (
                <button
                  onClick={() =>
                    this.setState({
                      notificationModal: true,
                      token: row.value.pushNotificationToken,
                    })
                  }
                  className="btn btn-primary"
                >
                  Push a notification
                </button>
              ) : (
                'Push token not setup'
              )}
            </div>
          );
        },
      },
      {
        id: 'tags',
        Header: 'Tags',
        accessor: (d) => d,
        filterable: false,
        sortable: true,
        Cell: this.renderTagColumn,
      },
      {
        id: 'tips',
        Header: 'Tips',
        accessor: (d) => d,
        filterable: false,
        sortable: false,
        Cell: (row) =>
          row.value.tips.length > 0 ? (
            <button onClick={() => this.onShowListOfTips(row.value)} className="btn btn-primary">
              Show
            </button>
          ) : (
            'No tips'
          ),
      },

      {
        id: 'RiveState',
        Header: 'RiveState',
        accessor: (d) => d,
        filterable: false,
        sortable: false,
        Cell: (row) => (
          <button
            onClick={async () => {
              try {
                await axios.post(
                  `${API_BASE_URL}/user/${row.value._id}/rivescript/reset`,
                  {},
                  {
                    withCredentials: true,
                  },
                );
                toast.success('Reset successfully.');
              } catch (err) {
                toast.error('Failed to reset');
              }
            }}
            className="btn btn-danger"
          >
            Reset
          </button>
        ),
      },
      {
        id: "BankAccountNumber",
        Header: "Bank Acct. No.",
        accessor: d => d,
        filterable: false,
        sortable: false,
        Cell: (row) => {
          if (!row.value.bank_account_number) return null;
          return <div>{row.value.bank_account_number}</div>;
        }
      },
      {
        id: "BankRoutingNumber",
        Header: "Bank Routing. No.",
        accessor: d => d,
        filterable: false,
        sortable: false,
        Cell: (row) => {
          if (!row.value.bank_routing_number) return null;
          return <div>{row.value.bank_routing_number}</div>;
        }
      }
    ];

    return (
      <div style={{ paddingTop: 25 }}>
        <h3>All Users</h3>
        <ToastContainer />
        <div style={{ paddingTop: 15 }} />
        <WrappedTable
          page={this.state.page}
          data={this.state.data}
          pages={this.state.pages}
          loading={this.state.loading}
          columns={columns}
          filterable={true}
          defaultSorted={[
            {
              id: 'resolved',
              desc: true,
            },
          ]}
          manual
          onFetchData={(state, instance) => this.dataFetcher(state, instance)}
          pageSize={this.state.pageSize}
          onPageSizeChange={(newSize) => {
            this.setState({ pageSize: newSize });
          }}
          onPageChange={(newPage) => {
            if (newPage < 0 || newPage >= this.state.pages) return;
            this.setState({
              page: newPage,
            });
          }}
        />
        <Modal
          show={this.state.notificationModal}
          onHide={() => this.setState({ notificationModal: false })}
        >
          <Modal.Header>
            <Modal.Title>Send notification to user phone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.onSubmitNotification}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Token</Form.Label>
                <Form.Control disabled value={this.state.token} />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  onChange={(e) => this.setState({ message: e.target.value })}
                  value={this.state.message}
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>From</Form.Label>
                <Form.Control
                  onChange={(e) => this.setState({ from: e.target.value })}
                  value={this.state.from}
                />
              </Form.Group>
              <button type="submit" className="btn btn-primary btn-block">
                Send
              </button>
            </Form>
          </Modal.Body>
        </Modal>
        {this.state.user && (
          <Modal
            size="xl"
            show={this.state.tipsModal}
            onHide={() => this.setState({ tipsModal: false, user: null })}
          >
            <Modal.Header>
              <Modal.Title>Tips</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactTable
                pageSize={10}
                columns={[
                  {
                    id: 'status',
                    Header: 'Status',
                    accessor: (d) => d,
                    Cell: (row) => {
                      return (
                        <div>
                          <select
                            value={row.value.status}
                            onChange={(e) => {
                              let user = this.state.user;
                              user.tips[row.index].status = e.target.value;
                              this.setState({ user });
                              axios.put(
                                `${API_BASE_URL}/user/${this.state.user._id}/update_tip/${
                                  row.value._id
                                }`,
                                {
                                  status: e.target.value,
                                },
                                {
                                  withCredentials: true,
                                },
                              );
                            }}
                          >
                            <option value="pending">Pending</option>
                            <option value="completed">Completed</option>
                            <option value="refunded">Refunded</option>
                            <option value="processing">Processing</option>
                            <option value="canceled">Canceled</option>
                          </select>
                        </div>
                      );
                    },
                  },
                  {
                    id: 'amount',
                    Header: 'Amount',
                    accessor: (d) => d.tipAmount,
                    Cell: (row) => {
                      return <div>${(row.value / 100).toFixed(2)}</div>;
                    },
                  },
                  {
                    id: 'createdAt',
                    Header: 'Created At',
                    accessor: (d) => d.createdAt,
                    Cell: (row) => DateTime.fromISO(row.value).toFormat('DD hh:mm a'),
                  },
                  {
                    id: 'updatedAt',
                    Header: 'Updated At',
                    accessor: (d) => d.updatedAt,
                    Cell: (row) => DateTime.fromISO(row.value).toFormat('DD hh:mm a'),
                  },
                  {
                    id: 'chargeId',
                    Header: 'Charge ID',
                    accessor: (d) => d.chargeId,
                  },
                ]}
                data={this.state.user.tips}
              />
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
}

class StripeChargeHandler extends Component {
  constructor(props) {
    super(props);
    this.modal = React.createRef();
    this.initialState = {
      chargingInProgress: false,
      confirmationReceived: false,
      amount: '',
      stripeError: null,
      stripeSuccess: null,
    };
    this.state = this.initialState;
  }

  handlePayment = async () => {
    let res;
    try {
      this.setState({
        chargingInProgress: true,
        stripeError: null,
      });
      res = await axios.post(
        API_BASE_URL + '/user/create_payment',
        {
          userToCharge: this.props.user._id,
          amount: this.state.amount,
        },
        { withCredentials: true },
      );
      this.setState({
        chargingInProgress: false,
        stripeSuccess: true,
      });
    } catch (err) {
      this.setState({
        chargingInProgress: false,
        stripeError: err.response.data.message,
      });
    }
  };

  onClick = async (e) => {
    e.preventDefault();
    this.setState(this.initialState);
    window.$(this.modal.current).modal('show');
  };

  onConfirmationChange = (e) => {
    this.setState({
      confirmationReceived: !this.state.confirmationReceived,
    });
  };

  onKeyPress = (e) => {
    if (e.key === 'e') {
      e.preventDefault();
    }
  };

  onAmountChange = (e) => {
    e.preventDefault();

    this.setState({
      amount: e.target.value.replace(/e/g, '').replace(/(\.\d{2})\d+/g, '$1')
    });
  };

  confirmationModal = () => {
    const {
      confirmationReceived,
      amount,
      stripeError,
      chargingInProgress,
      stripeSuccess,
    } = this.state;

    let confirmButtonText = chargingInProgress ? 'Charging...' : 'Charge';

    return (
      <div
        ref={this.modal}
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Creating Bank Account Charge</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                disabled={chargingInProgress}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>
                User:{' '}
                {this.props.user.phoneNumber || this.props.user.name || this.props.user.username}
              </h5>

              {stripeError && (
                <div className="form-text text-danger">Stripe Error: {stripeError}</div>
              )}

              {!stripeSuccess && (
                <div>
                  <div className="form-group">
                    <label htmlFor={`${'plaid_amount_' + this.props.user._id}`}>
                      Amount to charge
                    </label>
                    <input
                      type="number"
                      min="1"
                      step="0.01"
                      className="form-control"
                      id={`${'plaid_amount_' + this.props.user._id}`}
                      aria-describedby="emailHelp"
                      placeholder="Enter amount in dollars"
                      value={amount || ''}
                      onKeyDown={this.onKeyPress}
                      onChange={this.onAmountChange}
                      onPasteCapture={(e) => e.preventDefault()}
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      Amount is in dollars. Please double check to make sure the amount is correct.
                    </small>
                  </div>

                  <div>Are you sure you want to create this charge?</div>

                  <div className="custom-control custom-checkbox form-group">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={`${'plaid_check_' + this.props.user._id}`}
                      checked={confirmationReceived}
                      onChange={this.onConfirmationChange}
                      disabled={amount.length <= 0 || amount < 0.5}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`${'plaid_check_' + this.props.user._id}`}
                    >
                      I am sure
                    </label>
                  </div>

                  {amount.length <= 0 && (
                    <div className="form-text text-muted">Enter amount to enable checkbox</div>
                  )}

                  {amount.length > 0 && amount < 0.5 && (
                    <div className="form-text text-muted">Amount should be atleast $0.5 (50 cents)</div>
                  )}
                </div>
              )}

              {stripeSuccess && (
                <div className="text-center mt-3 mb-3">
                  <h3 className="mb-4">Charge Successful</h3>
                  <i className="far fa-check-circle" style={{ fontSize: 80, color: '#52B788' }} />
                  <h3 className="mt-3" style={{ color: '#52B788' }}>
                    ${amount}
                  </h3>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                disabled={chargingInProgress}
              >
                Close
              </button>
              {!stripeSuccess && (
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={!confirmationReceived || chargingInProgress}
                  onClick={(e) => this.handlePayment(e)}
                >
                  {confirmButtonText}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { chargingInProgress } = this.state;

    const buttonText = chargingInProgress ? 'please wait...' : 'Charge Acct';

    return (
      <div key={this.props.user._id}>
        {this.confirmationModal()}

        <button
          className="btn btn-info btn-sm"
          disabled={chargingInProgress}
          onClick={this.onClick}
        >
          {buttonText}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchAllUsers }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllUsers);
