import React, { Component } from 'react';
import cookie from "js-cookie";
import axios from "axios";
import { onConnectHandler, doChatLoginForAgent } from "../chatClient";
import { API_BASE_URL } from "../config";
import { setUser } from "../actions";
import { connect } from "react-redux";
import { getUser } from "../selectors";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			error: false,
			user: {},
			username: "",
			password: ""
		};
		this.handleChange = this.handleChange.bind(this);
		this.doLogin = this.doLogin.bind(this);
	}

	doLogin = async function (e) {
		e.preventDefault();
		try {
			var user = await axios.post(API_BASE_URL + "/auth/login", { username: this.state.username, password: this.state.password }, 
				{ withCredentials: true }
			);
			var inElevenHours = new Date(new Date().getTime() + 11 * 60 * 60 * 1000);
			cookie.set("chat.user", user.data, { expires: inElevenHours });
			var token = await axios.get(API_BASE_URL + "/agent/token", { withCredentials: true });
			cookie.set("user.jwt", token.data, { exipres: inElevenHours });
			await doChatLoginForAgent(token.data);
			this.props.setUser();
			await onConnectHandler({ isAuthenticated: false });
			this.props.history.push("/agent/chat");
		} catch (err) {
			console.error(err);
			if (err.response.status === 401) {
				alert("Invalid username or password. Please try again.");
			}
		}
  	}

    handleChange (e) {
  	  this.setState({[e.target.name]: e.target.value});
  	}

	render() {

		if (this.props.user._id) {
			return <Redirect to="/" />;
		}

		const { username, password } = this.state;

		return (
			<div style={{paddingTop: 25}}>
			  <h1>Login</h1>

			  <div className="row">
			  	<div className="col-md-5">
				  	<form onSubmit={this.doLogin}>
			            <input type="text" className="form-control mt-2" placeholder="username" name="username" 
			            value={this.state.username} onChange={this.handleChange} />
			            <input type="password" className="form-control mt-3" placeholder="password" name="password" 
			            value={this.state.password} onChange={this.handleChange} />
			            <button type="submit" className="btn btn-default mt-4">Login</button>
		          	</form>
	          	</div>
			  </div>
			</div>
		)
	}
}


const mapStateToProps = state => {
	return {
		user: getUser(state)
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ setUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
