export const CHAT_COMMAND = {
  OPTION: '082415option',
  PDF: '082415pdf',
  WEBVIEW_OPEN: '082415browsingOpen',
  WEBVIEW_CLOSE: '082415browsingClose',
  DOLLAR_AMOUNT: '082415dollars',
  PHOTO_INPUT: '082415photoInput',
  PHOTO_RESPONSE: 'photoInputResponse',
};

export const CHAT_PARAM = {
  DISPLAY: 'display=',
  LINK: 'link=',
};

export const tokenizeTextMessage = (message, chatCommand) => {
  return message.replace(`${chatCommand}:::`, '').split(':::');
};
