import React from 'react';
import { Tab, Row, Col, ListGroup } from 'react-bootstrap';
import Templates from './templates/index';

export default class TemplatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTemplateModal: false,
    };
  }
  onHideNewTemplateModal = () => {
    this.setState({ newTemplateModal: false });
  };
  onOpenNewTemplateModal = () => this.setState({ newTemplateModal: true });
  render() {
    return (
      <div className="pt-4 pb-4">
        <h1>Templates</h1>
        <hr />
        <Tab.Container id="list-group-tabs-example">
          <Row>
            <Col sm={4}>
              <ListGroup>
                {Object.keys(Templates).map((templateKey) => (
                  <ListGroup.Item key={templateKey} action href={`#${templateKey}`}>
                    {Templates[templateKey].name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                {Object.keys(Templates).map((templateKey) => (
                  <Tab.Pane key={templateKey} eventKey={`#${templateKey}`}>
                    {Templates[templateKey].template()}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    );
  }
}
