import cookie from "js-cookie";
window.xyzCookie = cookie;

const isAuthenticated = () => {
    const session = cookie.get("connect.sid");

    if (session) {
        var userCookie = cookie.get("chat.user");

        var userCookieP = cookie.get("chat.user", { path: "" });

        if (!userCookie) {
            if (userCookieP) {
                cookie.remove("chat.user", { path: "" });
                window.location = "/";
            }
            return false;
        }
        try {
            const user = JSON.parse(userCookie);
            return user;
        } catch (err) {
            return false;
        }
    }

    return false;
}

export default isAuthenticated;
