import React, { Component } from 'react';
import TimeAgo from 'react-timeago';
import { connect } from 'react-redux';
import { getMessagesForActiveRoom, getTypingText, getSelectedRoom } from './selectors';
import { setSelectedRoom } from './actions';
import { bindActionCreators } from 'redux';
import { CHAT_COMMAND, CHAT_PARAM, tokenizeTextMessage } from './utils/chat';
import { DateTime } from 'luxon';
import classNames from 'classnames';

class ChatMessageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  scrollToBottom() {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
  }

  componentDidMount() {
    var self = this;
    if (!window.messageToScrollTo) {
      this.scrollToBottom();
    }
  }

  scrollMessageIntoView(messageId) {
    let parentDiv = window.$('.messages');
    let innerListItem = window.$(`[data-id=${messageId}]`);

    if (!innerListItem.length) return;

    if (!parentDiv) return;

    parentDiv.scrollTop(
      parentDiv.scrollTop() +
        (innerListItem.position().top - parentDiv.position().top) -
        parentDiv.height() / 2 +
        innerListItem.height() / 2,
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    var self = this;

    if (prevProps.roomMessages.length !== this.props.roomMessages.length) {
      this.scrollToBottom();
    }
    // if (this.props.loadingFromAllRoomsTab === false) {
    //   if (prevProps.loadingFromAllRoomsTab === true) {
    //     if (window.messageToScrollTo) {
    //       setTimeout(function() {
    //         var roomId = window.messageToScrollTo;
    //         window.messageToScrollTo = null;
    //         window.requestAnimationFrame(() => self.scrollMessageIntoView(roomId));
    //       }, 850);
    //     }
    //   } else {
    //     this.scrollToBottom();
    //   }
    // }
  }

  messageClass = (msg) => {
    if (msg.isBotReply) {
      return 'replies';
    }
    if (msg.sender && this.props.profile.isAgent && msg.sender.isAgent) {
      return 'sent';
    }
    if (msg.sender && msg.sender._id !== this.props.profile._id) {
      return 'replies';
    }
    return 'sent';
  };
  renderChatMessage = (message) => {
    // If message from bot
    if ((message.isBotReply || (message.sender && !message.sender.isAgent)) && message.text) {
      // Check if text message includes CHAT_COMMAND.OPTION
      if (message.text.includes(CHAT_COMMAND.OPTION)) {
        // Break message into array
        const tokenizeMessage = tokenizeTextMessage(message.text, CHAT_COMMAND.OPTION);
        // Parse tokenizeMessage
        let chatMessageObject = tokenizeMessage.reduce(
          (messageObject, currentValue) => {
            if (currentValue.includes(CHAT_PARAM.DISPLAY)) {
              messageObject.message = currentValue.replace(CHAT_PARAM.DISPLAY, '');
            } else {
              messageObject.options.push(currentValue);
            }

            return messageObject;
          },
          {
            message: '',
            options: [],
          },
        );
        return (
          <div>
            <p
              className={classNames({
                botMessage: message.isBotReply,
              })}
            >
              [BOT] {chatMessageObject.message} 🤖
            </p>
          </div>
        );
      } else if (message.text.includes(CHAT_COMMAND.PHOTO_RESPONSE)) {
        const tokenizeMessage = tokenizeTextMessage(message.text, CHAT_COMMAND.PHOTO_RESPONSE);
        // Parse tokenizeMessage
        let chatMessageObject = tokenizeMessage.reduce(
          (messageObject, currentValue) => {
            if (currentValue.includes(CHAT_PARAM.DISPLAY)) {
              messageObject.message = currentValue.replace(CHAT_PARAM.DISPLAY, '');
            } else if (currentValue.includes(CHAT_PARAM.LINK)) {
              messageObject.link = currentValue.replace(CHAT_PARAM.LINK, '');
            }
            return messageObject;
          },
          {
            message: '',
            link: '',
          },
        );
        return (
          <div className="userMessage mb-2">
            {chatMessageObject.link && (
              <a rel="noopener noreferrer" target="_blank" href={chatMessageObject.link}>
                <img
                  alt="img"
                  className="w-100 h-100 mb-3"
                  style={{ borderRadius: 0 }}
                  src={chatMessageObject.link}
                />
              </a>
            )}
          </div>
        );
      }
      return (
        <div className={classNames({
          userMessage: !message.isBotReply,
        })}>
          <p
            className={classNames('mb-2 userMessage', {
              botMessage: message.isBotReply,
            })}
          >
            {message.isBotReply && '[BOT]'} {message.text} {message.isBotReply && '🤖'}
          </p>
        </div>
      );
    }

    return (
      <div className="userMessage mb-2">
        <p className="mb-0">{message.text}</p>
      </div>
    );
  };
  render() {
    const resolves = (this.props.selectedRoom ? this.props.selectedRoom.resolves || [] : []).map(
      (item) => ({ updatedAt: item, resolved: true }),
    );
    const messages = [...this.props.roomMessages, ...resolves].sort((a, b) => {
      a = new Date(a.updatedAt);
      b = new Date(b.updatedAt);
      return a > b ? 1 : a < b ? -1 : 0;
    });
    const self = this;
    return (
      <div className="messages">
        <ul>
          {messages.map((message) => {
            if (message.resolved) {
              return (
                <div className="text-center mb-4 pb-4" key={message.updatedAt}>
                  Resolved - {DateTime.fromISO(message.updatedAt).toFormat('DD hh:mm:ss a')}
                </div>
              );
            }
            var messageClass = self.messageClass(message);

            return (
              <li className={messageClass} key={message._id} data-id={message._id}>
                <div>
                  {this.renderChatMessage(message)}
                  {/* <img src="http://emilcarlsson.se/assets/mikeross.png" alt="" /> */}
                  <span>
                    {/* {message.text} <br /> */}
                    {/* Room: { message.room }<br/>
									_id: { message._id }<br/>
									participant: { message.room.participants } */}
                    <TimeAgo
                      date={message.createdAt}
                      className="liveTimestamp"
                      live={true}
                      minPeriod={60}
                      formatter={(value, unit, suffix) => {
                        if (unit === 'second') {
                          return 'just now';
                        } else {
                          return value + ' ' + unit + (value > 1 ? 's' : '') + ' ' + suffix;
                        }
                      }}
                    />
                    {/* {message.isBotReply && '🤖'} */}
                  </span>
                </div>
              </li>
            );
          })}
          {this.props.typingMessage && (
            <li className={'replies typingIndicatorBubble'} key={'typingMessage'}>
              <p>
                {this.props.typingMessage}
                &nbsp;
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
                <br />
              </p>
            </li>
          )}
        </ul>
        <div
          style={{ float: 'left', clear: 'both' }}
          ref={(el) => {
            this.messagesEnd = el;
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedRoom: getSelectedRoom(state),
    roomMessages: getMessagesForActiveRoom(state),
    typingMessage: getTypingText(state),
    loadingFromAllRoomsTab: state.rooms.loadingFromAllRoomsTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setSelectedRoom }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatMessageList);
