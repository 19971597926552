import { SET_USER, SET_LOGGED_OUT } from "../actions";

const initialState = {
    userId: null,
    user: null
}

const user = (state = {
    userId: null,
    loggingIn: true
 }, action) => {
     switch (action.type) {
        case SET_USER:
            return Object.assign({}, {
                loggingIn: false,
                userId: action.user._id,
                ...action.user
            });
        case SET_LOGGED_OUT:
            return Object.assign({}, initialState, {
                loggingIn: false
            });
        default:
            return state;
    }
}

export default user;
