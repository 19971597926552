import React, { Component } from 'react';
import cookie from "js-cookie";
import chatClient from "../chatClient";

class Home extends Component {
	constructor(props) {
		super(props);
		this.doLogout = this.doLogout.bind(this);
	}

	doLogout() {
		cookie.remove("connect.sid", { path: "" });
		cookie.remove("connect.sid");
		cookie.remove("chat.user");
		cookie.remove("chat.user", { path: "" });
		chatClient.deauthenticate(function (err, res) {
			window.location = "/";
		});
		// this.props.history.push("/?logout");
	}

	logoutBtn() {
		return (
			<button className="btn btn-danger" onClick={this.doLogout}>Logout</button>
		)
	}

	render() {
		return (
			<div style={{paddingTop: 25}}>
				<h1>Home</h1>
				<h4>Choose one of the links from the navbar above to get started!</h4>
			</div>
		)
	}
}

export default Home;
