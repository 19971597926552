import {
    API_BASE_URL
} from "./config";
import store from "./store";

const firebase = require("firebase/app");
const axios = require("axios");

require("firebase/messaging");

var config = {
    apiKey: "AIzaSyBEfFagtX7B6Vc0u_kobEskPZ9LedmiIBc",
    projectId: "dnpchatasaddev",
    messagingSenderId: "774600255418"
};

firebase.initializeApp(config);

console.log(`Push notifications supported: ${firebase.messaging.isSupported()}`);

if (firebase.messaging.isSupported()) {

    const messaging = firebase.messaging();

    messaging.usePublicVapidKey("BK3nw_zVgEzGMXfavNsXg0LpRmPVjglNMoVYrcAEnRJAH8cu4l8YUORBjyQ6MfTWj5OSEd93T8pJU_7brS3J2ac");

    messaging.requestPermission().then(function () {
        console.log('Notification permission granted.');
        // TODO(developer): Retrieve an Instance ID token for use with FCM.
        // ...
    }).catch(function (err) {
        console.log('Unable to get permission to notify.', err);
    });

    const sendTokenToServer = async (token) => {
        try {
            await axios.post(API_BASE_URL + "/agent/setPlayerId", {
                playerId: token
            }, {
                withCredentials: true
            });
        } catch (err) {

        }
    }

    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    messaging.getToken().then(function (currentToken) {
        if (currentToken) {
            // console.log(currentToken);
            try {
                sendTokenToServer(currentToken);
            } catch (err) {

            }
            // sendTokenToServer(currentToken);
            // updateUIForPushEnabled(currentToken);
        } else {
            // Show permission request.
            console.log('No Instance ID token available. Request permission to generate one.');
            // Show permission UI.
            // updateUIForPushPermissionRequired();
            // setTokenSentToServer(false);
        }
    }).catch(function (err) {
        console.log('An error occurred while retrieving token. ', err);
        // showToken('Error retrieving Instance ID token. ', err);
        // setTokenSentToServer(false);
    });

    // Callback fired if Instance ID token is updated.
    messaging.onTokenRefresh(function () {
        messaging.getToken().then(function (refreshedToken) {
            console.log('Token refreshed.');
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            // setTokenSentToServer(false);
            // Send Instance ID token to app server.
            try {
                sendTokenToServer(refreshedToken);
            } catch (err) {

            }
            // ...
        }).catch(function (err) {
            console.log('Unable to retrieve refreshed token ', err);
            // showToken('Unable to retrieve refreshed token ', err);
        });
    });

    messaging.onMessage(function (payload) {
        console.log('Message received. ', payload);

        if (store.getState().rooms.selectedRoom === payload.data.roomId) return;

        let notification = new Notification(payload.notification.title, { 
            icon: payload.notification.icon,
            body: payload.notification.body
         });
    });
}
