import React, { Component } from 'react';
import './App.scss';
import chatClient from "./chatClient";
import ChatWidget from "./ChatWidget";
import _ from "lodash";
import axios from "axios";
import { Router, Route, Switch } from "react-router-dom";
import Home from "./home/Home";
import Login from "./users/Login";
import Register from "./users/Register";
import ReqUser from "./users/ReqUser";
import ReqUsername from "./users/ReqUsername";
import history from "./historyUtil";
import NewRoom from "./rooms/NewRoom";
import RiveEditor from "./RiveEditor";
import Navbar from "./Navbar";
import History from "./rooms/History";
import AllRooms from "./rooms/AllRooms";
import ClaimedRooms from "./rooms/ClaimedRooms";
import PlaidPage from "./PlaidPage";
import AllUsersPage from "./AllUsers";
import { ChatMessagesStore } from "./ChatWidget";
import { API_BASE_URL } from "./config";
import { ToastContainer } from "react-toastr";
import ToastMessagejQuery from "react-toastr/lib/components/ToastMessage/ToastMessagejQuery";
import store from "./store";
import { Provider } from 'react-redux';
import { setUser } from "./actions";
import cookie from "js-cookie";
import ShortcutsPage from './ShortcutsPage';
import PersonaPage from './PersonaPage';
import TemplatePage from './TemplatePage';


class App extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: ""
    }

    this.chatRoom = null;
    this.doLogin = this.doLogin.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let self = this;
    this.registerPlayerId();
    store.dispatch(setUser());
    window.OneSignal && window.OneSignal.on && window.OneSignal.on('subscriptionChange', function (isSubscribed) {
      console.log("The user's subscription state is now:", isSubscribed);
      self.registerPlayerId();
    });
    cookie.remove("user.jwt", { path: "" });
		cookie.remove("user.jwt");
  }

  doLogin = async function (e) {
    e.preventDefault();
    await axios.post(API_BASE_URL + "/auth/login", { username: this.state.username, password: this.state.password }, 
      { withCredentials: true }
    );
    await this.registerPlayerId();
  }

  registerPlayerId = async function () {
    try {
      if (!window.OneSignal) return;
      if (!window.OneSignal.getUserId) return;
      let oneSignalPlayerId = await window.OneSignal.getUserId();

      if (await window.OneSignal.isPushNotificationsEnabled && await window.OneSignal.isPushNotificationsEnabled()) {

      } else {
        
      }

      if (!oneSignalPlayerId) return;
      console.log("OneSignal playerId: ", oneSignalPlayerId);
      
    } catch (err) {
      console.error(err);
    }
  }

  testReq = async function () {
    await axios.get(API_BASE_URL + "/test", { withCredentials: true });
  }

  handleChange (e) {
    this.setState({[e.target.name]: e.target.value});
  }

  render() {

    return (
      <Provider store={store}>
        <Router history={history} >
          <div>
            <ToastContainer
              ref={ref => window.toastContainer = ref}
              toastMessageFactory={React.createFactory(ToastMessagejQuery)}
              className="toast-top-right"
            />
                <Switch>
                  <div> 
                      <Route path="/" component={Navbar} />
                      <div className="container">
                          <div className="App">
                              <Route exact path="/" component={Home} />
                              <Route exact path="/secret" component={ReqUser(Home)} />
                              <Route exact path="/login" component={Login} />
                              <Route exact path="/register" component={Register} />
                              <Route exact path="/rooms/new" component={NewRoom} />
                              <Route exact path="/rooms/archived" component={History} />
                              <Route exact path="/rooms/claimed" component={ClaimedRooms} />
                              <Route exact path="/rooms/all" component={AllRooms} />
                              <Route exact path="/plaid" component={PlaidPage} />
                              <Route exact path="/users/all" component={ReqUser(AllUsersPage)} />
                              <Route exact path="/shortcuts" component={ReqUser(ShortcutsPage)} />
                              <Route exact path="/persona" component={ReqUser(PersonaPage)} />
                              <Route exact path="/templates" component={ReqUser(TemplatePage)} />
                          </div>
                          </div>
                          <div className="container-fluid">
                              <Route exact path="/agent/chat" component={ReqUser(ChatWidget)} />
                              <Route exact path="/rive" component={RiveEditor} />
                          </div>
                    </div>
                  </Switch>
                </div>
          </Router>
        </Provider>
    );
  }
}

export default App;
