import chatClient from "../chatClient";
import { addPulsingSignToRoom, updateRoom } from "./rooms";

export const FETCH_MESSAGES_FOR_ROOM = "FETCH_MESSAGES_FOR_ROOM";
export const ADD_MESSAGE = "ADD_MESSAGE";

export function addMessage(message) {
    message.createdAt = new Date(message.createdAt);
    message.updatedAt = new Date(message.updatedAt);
    return {
        type: ADD_MESSAGE,
        message
    }
}

export function receiveMessage(message, isNew=false) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(addMessage(message));
        dispatch(addPulsingSignToRoom(message.room));
        if (getState().rooms.roomsWithMessages.indexOf(message.room) === -1) {
            let roomIndex = getState().rooms.items.findIndex((room) => room._id === message.room);
            let room = getState().rooms.items[roomIndex];
            room.msg_count = 1;
            dispatch(updateRoom(room, true));
        }
        resolve();
    });
}

export function fetchMessagesForRoom(roomId, fullHistory = false, count = 50) {
    return dispatch => new Promise((resolve, reject) => {
        chatClient.emit("load_chat_room", { room: roomId, fullHistory, count: count }, function (err, res) {
            let messages = [];
            if (err) return reject(err);
            let messagesCount = res.length;
            if (messagesCount === 0) return resolve();
            let messagesAdded = 0;
            res.map(function (message) {
                messages.push(addMessage(message));
                messagesAdded += 1;
                // console.log("adding message");
                // if (self.messageIds.indexOf(message._id) === -1) {
                // await self.addMessage(message, false);

                // if (messagesAdded === messagesCount) {
                // }
                // }
            });
            dispatch(messages);
            return resolve();
        });
    });
}

export function sendMessage(message) {
    return dispatch => new Promise((resolve, reject) => {
        chatClient.emit("send_chat_message", message, function (err, res) {
            if (err) {
                console.error(err);
                return reject(err);
            }
            let messages = [];
            console.log(res);
            [].concat(res).forEach(message => messages.push(addMessage(message)));
            try {
                dispatch(messages);
            } catch (err) {
                console.log(messages);
                console.warn(err);
            }
            return resolve(res);
        });
    });
}
