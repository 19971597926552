import React from 'react';
import 'react-table/react-table.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Axios from 'axios';
import { API_BASE_URL } from './config';

window.toaster = toast;

export default class PersonaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inquiryId: '',
      inquiryResult: {},
    };
  }
  onGetPersonaInformation = async (e) => {
    e.preventDefault();
    if (!this.state.inquiryId) {
      return;
    }
    try {
      const result = await Axios.get(`${API_BASE_URL}/inquiries/${this.state.inquiryId}`, {
        withCredentials: true,
      });
      if (result.data) {
        this.setState({ inquiryResult: result.data });
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  render() {
    return (
      <div className="pt-4">
        <ToastContainer />
        <h1>Persona</h1>
        <hr />
        <form onSubmit={this.onGetPersonaInformation}>
          <input
            value={this.state.inquiryId}
            onChange={(e) => this.setState({ inquiryId: e.target.value })}
            className="form-control"
            placeholder="Inquiry ID"
          />
          <button
            type="submit"
            onClick={() => this.setState({ createModal: true })}
            className="btn btn-primary mt-2 mr-2"
          >
            Get Information
          </button>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://withpersona.com/verify?blueprint-id=blu_v272xVEz2s9NwVXSo5w3QqYD&redirect-uri=https://chat.donotpay.com/verification_completed"
            className="btn btn-success mt-2 mr-2"
          >
            Verify Link
          </a>

          {Object.keys(this.state.inquiryResult).length > 0 && (
            <div className="mt-4">
              <h2>Result</h2>
              <textarea className="w-100" rows={15}>
                {JSON.stringify(this.state.inquiryResult, null, 4)}
              </textarea>
            </div>
          )}
        </form>
      </div>
    );
  }
}
