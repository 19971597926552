import store from "../store";
import chatClient from "../chatClient";
import { fetchMessagesForRoom } from "./messages";
import { getMessagesForActiveRoom } from "../selectors";

export const ADD_ROOM = "ADD_ROOM";
export const UPDATE_ROOM = "UPDATE_ROOM";
export const SET_SELECTED_ROOM = "SET_SELECTED_ROOM";
export const SELECT_DEFAULT_ROOM = "SELECT_DEFAULT_ROOM";
export const FINISH_LOADING_ROOMS = "FINISH_LOADING_ROOMS";
export const OPEN_ROOM_FROM_HISTORY = "OPEN_ROOM_FROM_HISTORY";
export const OPEN_CLAIMED_ROOM = "OPEN_CLAIMED_ROOM";
export const CLAIM_ROOM = "CLAIM_ROOM";
export const TRANSFER_ROOM = "TRANSFER_ROOM";
export const RESOLVE_ROOM = "RESOLVE_ROOM";
export const CLOSE_OPEN_HISTORY_ROOM = "CLOSE_OPEN_HISTORY_ROOM";
export const CLOSE_OPEN_CLAIMED_ROOM = "CLOSE_OPEN_CLAIMED_ROOM";
export const LOAD_FULL_ROOM_HISTORY = "LOAD_FULL_ROOM_HISTORY";
export const ADD_HISTORY_ROOM_MAPPING = "ADD_HISTORY_ROOM_MAPPING";
export const ADD_PULSING_SIGN_TO_ROOM = "ADD_PULSING_SIGN_TO_ROOM";
export const ADD_SUBSCRIBED_ROOM = "ADD_SUBSCRIBED_ROOM";
export const ADD_ROOM_TO_ALL_ROOMS_TAB = "ADD_ROOM_TO_ALL_ROOMS_TAB";
export const FINISH_LOADING_FROM_ALL_ROOMS_TAB = "FINISH_LOADING_FROM_ALL_ROOMS_TAB";



// Add a room to the store
function _addRoom(room) {
    return {
        type: ADD_ROOM,
        room
    }
}

function _updateRoom(room) {
    return {
        type: UPDATE_ROOM,
        room
    }
}

function _finishLoadingFromAllRoomsTab () {
    return {
        type: FINISH_LOADING_FROM_ALL_ROOMS_TAB
    }
}

export function updateRoom(room, forceUpdate=false) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        let roomIndex = getState().rooms.items.findIndex((r) => r._id === room._id);
        let currentRoom = getState().rooms.items[roomIndex];
        if (!currentRoom) {
            dispatch(addRoom(room));
        } else {
            if (forceUpdate === true) {
                dispatch(_updateRoom(room));
                return resolve();
            }
            if (currentRoom.updatedAt < room.updatedAt) {
                dispatch(_updateRoom(room));
            }
        }
        resolve();
    });
}

// Set the currently selected room in the store
function _setSelectedRoom(roomId) {
    return {
        type: SET_SELECTED_ROOM,
        roomId
    }
}

function _addRoomToHistory(room) {
    return {
        type: OPEN_ROOM_FROM_HISTORY,
        room
    }
}

function _addClaimedRoom(room) {
    return {
        type: OPEN_CLAIMED_ROOM,
        room
    }
}

function addSubscribedRoom(roomId) {
    return {
        type: ADD_SUBSCRIBED_ROOM,
        roomId
    }
}

window.chatClient = chatClient;

export function addRoom(room) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch(_addRoom(room));
        dispatch(subscribeToRoom(room._id));
        resolve();
    });
}

// Set a selected room in the store if one isn't already selected
export function selectDefaultRoom() {
    return dispatch => new Promise((resolve, reject) => {
        let roomState = store.getState().rooms;
        if (roomState.selectedRoom) return resolve();
        for (var i = 0; i < roomState.items.length; i++) {
            if (!roomState.items[i].resolved) {
                dispatch(setSelectedRoom(roomState.items[i]._id));
                return resolve();
            }
        }
        if (!store.getState().selectedRoom) {
            if (roomState.items.length > 0) {
                dispatch(setSelectedRoom(roomState.items[0]._id));
                return resolve();
            } 
        }
        resolve();
    });
}

// Mark initial loading of room list complete to hide loading text in room list sidebar
export function finishLoadingRooms() {
    return {
        type: FINISH_LOADING_ROOMS
    }
}

export function updateRooms() {
    return dispatch => new Promise((resolve, reject) => {
        chatClient.emit("fetch_rooms", {}, function (err, res) {
            console.log(res)
            if (err) return reject(err);
            res.map((room) => {
                // Convert timestamp strings to date objects
                if (room.createdAt) {
                    room.createdAt = new Date(room.createdAt);
                }
                if (room.updatedAt) {
                    room.updatedAt = new Date(room.updatedAt);
                }
                if (room.resolved) {
                    room.resolved = new Date(room.resolved);
                }
                dispatch(addRoom(room));
            });
            dispatch(selectDefaultRoom());
            dispatch(finishLoadingRooms());
            resolve(res);
        });
    });
}

export function fetchRooms() {
    return dispatch => new Promise((resolve, reject) => {
        chatClient.emit("fetch_rooms", {}, function (err, res) {
            if (err) return reject(err);
            res.map((room) => {
                // Convert timestamp strings to date objects
                if (room.createdAt) {
                    room.createdAt = new Date(room.createdAt);
                }
                if (room.updatedAt) {
                    room.updatedAt = new Date(room.updatedAt);
                }
                if (room.resolved) {
                    room.resolved = new Date(room.resolved);
                }
                dispatch(addRoom(room));
            });
            dispatch(selectDefaultRoom());
            dispatch(finishLoadingRooms());
            resolve(res);
        });
    });
}

export function fetchResolvedRooms(data) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        chatClient.emit("fetch_resolved_rooms", data, function (err, res) {
            if (err) return reject(err);
            res.rooms.map(function (room) {
                room.createdAt = new Date(room.createdAt);
                room.updatedAt = new Date(room.updatedAt);
                room.resolved = new Date(room.resolved);
                if (getState().rooms.roomIds.indexOf(room._id) === -1) {
                    dispatch(addRoom(room));
                } else {
                    dispatch(updateRoom(room));
                }
                return room;
            });
            return resolve({ data: res.rooms, count: res.roomCount });
        });
    });
}

export function fetchClaimedRooms(data) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        chatClient.emit("fetch_claimed_rooms", data, function (err, res) {
            if (err) return reject(err);
            let rooms = [];
            res.rooms.map(function (room) {
                room.createdAt = new Date(room.createdAt);
                room.updatedAt = new Date(room.updatedAt);
                if (room.resolved) {
                    room.resolved = new Date(room.resolved);
                }
                if (getState().rooms.roomIds.indexOf(room._id) === -1) {
                    dispatch(addRoom(room));
                } else {
                    rooms.push(updateRoom(room));
                }
                return room;
            });
            dispatch(rooms);
            return resolve({ data: res.rooms, count: res.roomCount });
        });
    });
}

export function fetchAllRooms(data) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        if (!chatClient.connected) {
            let err = new Error("Chat client not connected");
            err.name = "CHAT_CLIENT_DISCONNECTED";
            return reject(err);
        }
        chatClient.emit("fetch_all_rooms", data, function (err, res) {
            if (err) return reject(err);
            let rooms = [];
            res.rooms.map(function (room) {
                room.createdAt = new Date(room.createdAt);
                room.updatedAt = new Date(room.updatedAt);
                if (room.resolved) {
                    room.resolved = new Date(room.resolved);
                }
                if (getState().rooms.roomIds.indexOf(room._id) === -1) {
                    dispatch(addRoom(room));
                } else {
                    dispatch(updateRoom(room));
                    // rooms.push(updateRoom(room));
                }
                return room;
            });
            // dispatch(rooms);
            return resolve({ data: res.rooms, count: res.roomCount });
        });
    });
}

export function setSelectedRoom(roomId, allRoomsTab=false) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        dispatch(_setSelectedRoom(roomId));
        dispatch(fetchMessagesForRoom(roomId)).then(() => {
            if (allRoomsTab) {
                var firstMessageIndex = getMessagesForActiveRoom(getState()).findIndex((msg) => msg.room === roomId);
                if (firstMessageIndex === -1) {
                    firstMessageIndex = 0;
                }
                var firstMessage = getMessagesForActiveRoom(getState())[firstMessageIndex];
                if (firstMessage) {
                    window.messageToScrollTo = firstMessage._id;
                }
            }
            resolve();
        }).catch((err) => {
            reject(err);
        });
    });
}

export function openRoomFromHistory(room) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch(_addRoomToHistory(room));
        dispatch(loadFullRoomHistory(room));
        dispatch(setSelectedRoom(room._id));
        resolve();
    });
}

export function closeOpenHistoryRoom(roomId) {
    return {
        type: CLOSE_OPEN_HISTORY_ROOM,
        roomId
    }
}

// Used when a room that is not selected has a new message come in
export function addPulsingSignToRoom(roomId) {
    return {
        type: ADD_PULSING_SIGN_TO_ROOM,
        roomId
    }
}


export function closeOpenClaimedRoom(roomId) {
    return {
        type: CLOSE_OPEN_CLAIMED_ROOM,
        roomId
    }
}

export function addHistoryRoomMapping(roomId, relatedRoomIds) {
    return {
        type: ADD_HISTORY_ROOM_MAPPING,
        roomId,
        relatedRoomIds
    }
}

export function openClaimedRoom(room) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch(_addClaimedRoom(room));
        dispatch(setSelectedRoom(room._id));
        resolve();
    });
}

function _addRoomToAllRoomsTab(room) {
    return {
        type: ADD_ROOM_TO_ALL_ROOMS_TAB,
        room
    }
}

export function openRoomFromAllRoomsTab(room) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        if (getState().rooms.roomIds.indexOf(room._id) === -1) {
            dispatch(addRoom(room));
        } else {
            dispatch(updateRoom(room));
        }
        dispatch(_addRoomToAllRoomsTab(room));
        dispatch(loadFullRoomHistory(room)).then(() => {
            dispatch(setSelectedRoom(room._id, true)).then(() => {
                dispatch(_finishLoadingFromAllRoomsTab());
                resolve();
            });
        });
    });
}

export function claimRoom(roomId) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        chatClient.emit("claim_chat_room", { room: roomId }, function (err, res) {
            if (err) {
                return reject();   
            }
            // Convert timestamp strings to date objects
            if (res.createdAt) {
                res.createdAt = new Date(res.createdAt);
            }
            if (res.updatedAt) {
                res.updatedAt = new Date(res.updatedAt);
            }
            if (res.resolved) {
                res.resolved = new Date(res.resolved);
            }
            dispatch(updateRoom(res));
            return resolve();
        });
    });
}

export function transferRoom(roomId) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        chatClient.emit("claim_chat_room", { room: roomId, release: true }, function (err, res) {
            if (err) return reject();
            if (res.createdAt) {
                res.createdAt = new Date(res.createdAt);
            }
            if (res.updatedAt) {
                res.updatedAt = new Date(res.updatedAt);
            }
            if (res.resolved) {
                res.resolved = new Date(res.resolved);
            }
            dispatch(updateRoom(res));
            return resolve();
        });
    });
}

export function resolveRoom(roomId) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        chatClient.emit("resolve_chat_room", { room: roomId }, function (err, res) {
            if (err) return reject();
            if (res.createdAt) {
                res.createdAt = new Date(res.createdAt);
            }
            if (res.updatedAt) {
                res.updatedAt = new Date(res.updatedAt);
            }
            if (res.resolved) {
                res.resolved = new Date(res.resolved);
            }
            dispatch(updateRoom(res));
            return resolve();
        });
    });
}

// Used when loading messages across all rooms a user has been a part of at once
// In case a room is resolved, a new one is created for the customer,
// but the messages still need to be presented in a single stream
export function loadFullRoomHistory(room) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        chatClient.emit("get_rooms_for_customer", { participantId: room.participants[0]._id }, function (err, res) {
            // Map each room to other rooms from same customer for quick lookups on history view
            if (err) return reject(err);
            res.roomIds.map(roomId => {
                dispatch(addHistoryRoomMapping(roomId, res.roomIds));
            });
            dispatch(fetchMessagesForRoom(room._id, true, 500)).then(() => {
                return resolve();
            });
        })
    });
}


// Subscribe to room
export function subscribeToRoom(roomId) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        chatClient.emit("subscribe", roomId);
        dispatch(addSubscribedRoom(roomId))
        resolve();
    });
}

// Refresh room subscriptions
export function refreshRoomSubscriptions() {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        let subscribedRooms = getState().rooms.subscribedRooms;
        let roomSubs = [];
        subscribedRooms.map((roomId) => {
            dispatch(subscribeToRoom);
        });
    });
}
