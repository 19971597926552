import { ADD_MESSAGE } from "../actions";

const messages = (state = { 
    items: [],
    typingNotificationText: null,
    messageIds: []
 }, action) => {
    switch (action.type) {
        case ADD_MESSAGE:
            if (state.messageIds.indexOf(action.message._id) !== -1) return state;
            return Object.assign({}, state, {
               items: [...state.items, action.message],
               messageIds: Array.from(new Set([...state.messageIds, action.message._id]))
            });
        default:
            return state;
    }
}

export default messages;
