import React, { Component } from 'react';
import cookie from "js-cookie";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { setUser } from "../actions";
import { connect } from "react-redux";
import { getUser } from "../selectors";
import { bindActionCreators } from "redux";


class Register extends Component {
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.state = {
			name: "",
			username: "",
			password: "",
			password2: "",
			secret: ""
		}
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange (e) {
  	  this.setState({[e.target.name]: e.target.value});
  	}

	onSubmit = async function (e) {
		e.preventDefault();
		if (this.state.password !== this.state.password2) {
			return alert("Password confirmation needs to be same as password");
		}
		try {
			const user = await axios.post(API_BASE_URL + "/auth/register", { ...this.state }, 
				{ withCredentials: true });
			if (user) {
				var inElevenHours = new Date(new Date().getTime() + 11 * 60 * 60 * 1000);
				cookie.set("chat.user", user.data, { expires: inElevenHours });
				this.props.setUser();
				alert("User with username " + this.state.username + " successfully created!");
				window.location = "/";
			}
		} catch (err) {
			return alert(err.response.data.message);
		}
	}

	render() {
		const { password, username, name, password2, secret } = this.state;

		return (
			<div style={{ paddingTop: 40, paddingBottom: 25 }}>
				<h2>Create Agent Account</h2>
				<div className="row">
					<div className="col-md-4 col-sm-8 col-xs-8">
						<form onSubmit={this.onSubmit}>
							<label htmlFor="name">Name</label>
							<input type="text" name="name" className="form-control" required={true}
								placeholder="Enter your name" value={name} onChange={this.handleChange} />
							<label htmlFor="username">Username</label>
							<input type="text" name="username" className="form-control" required={true}
								 placeholder="Select a username" value={username} onChange={this.handleChange} autoComplete="username" />
							<label htmlFor="password">Password</label>
							<input type="password" name="password" className="form-control" required={true}
								 placeholder="*******" value={password} onChange={this.handleChange} autoComplete="new-password" />
							<label htmlFor="password2">Confirm Password</label>
							<input type="password" name="password2" className="form-control" required={true}
								 placeholder="*******" value={password2} onChange={this.handleChange} autoComplete="new-password" />
							<label htmlFor="secret">Secret</label>
							<input type="password" value={secret} name="secret" className="form-control"
								required={true} onChange={this.handleChange} placeholder="********" />
							<br />
							<button type="submit" className="btn btn-primary">Register</button>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		user: getUser(state)
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ setUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
