import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import cookie from 'js-cookie';
import { socketDeauthenticator } from './chatClient';
import { getUser } from './selectors';
import { connect } from 'react-redux';
import { setLoggedOut } from './actions';
import { bindActionCreators } from 'redux';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();

    const link = e.target.href;

    if (link) {
      this.props.history.push(link);
    }
  }

  doLogout = () => {
    cookie.remove('connect.sid', { path: '' });
    cookie.remove('connect.sid');
    cookie.remove('chat.user');
    cookie.remove('chat.user', { path: '' });
    this.props.setLoggedOut();
    socketDeauthenticator();
  };

  render() {
    const authenticated = this.props.user;
    const username = authenticated
      ? authenticated.name || authenticated.username || authenticated.phoneNumber
      : null;

    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <NavLink to="/" className="navbar-brand">
          Chat App
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink to="/" className="nav-link" activeClassName={'active'} exact={true}>
                Home
              </NavLink>
            </li>
            {authenticated && [
              <li className="nav-item" key="1">
                <NavLink to="/agent/chat" className="nav-link" activeClassName={'active'}>
                  Chat
                </NavLink>
              </li>,
            ]}
            {authenticated &&
              authenticated.isAgent && [
                <li className="nav-item" key="1">
                  <NavLink to="/rive" className="nav-link" activeClassName={'active'}>
                    Rive Editor
                  </NavLink>
                </li>,
                <li className="nav-item" key="4">
                  <NavLink to="/rooms/all" className="nav-link" activeClassName={'active'}>
                    All Rooms
                  </NavLink>
                </li>,
                <li className="nav-item" key="5">
                  <NavLink to="/rooms/new" className="nav-link" activeClassName={'active'}>
                    New Room
                  </NavLink>
                </li>,
                <li className="nav-item" key="7">
                  <NavLink to="/users/all" className="nav-link" activeClassName={'active'}>
                    All Users
                  </NavLink>
                </li>,
                <li className="nav-item" key="6">
                  <NavLink to="/plaid" className="nav-link" activeClassName={'active'}>
                    Plaid Test
                  </NavLink>
                </li>,
                <li className="nav-item" key="71">
                  <NavLink to="/shortcuts" className="nav-link" activeClassName={'active'}>
                    Shortcuts
                  </NavLink>
                </li>,
                <li className="nav-item" key="55">
                  <NavLink to="/persona" className="nav-link" activeClassName={'active'}>
                    Persona
                  </NavLink>
                </li>,
                <li className="nav-item" key="5574">
                  <NavLink to="/templates" className="nav-link" activeClassName={'active'}>
                    Templates
                  </NavLink>
                </li>,
              ]}
          </ul>
          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {authenticated ? username : 'Account'}
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                {!authenticated && [
                  <Link to="/login" className="dropdown-item" key={1}>
                    Login
                  </Link>,
                  <Link to="/register" className="dropdown-item" key={2}>
                    Register
                  </Link>,
                ]}
                {authenticated && [
                  <a className="dropdown-item" href="#" onClick={this.doLogout} key="1">
                    Logout
                  </a>,
                ]}
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setLoggedOut }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Navbar);
