import chatClient from "../chatClient";

export const USERS_ADD = "USERS_ADD";
export const USERS_UPDATE = "USERS_UPDATE";

export function usersAdd(user) {
    return {
        type: USERS_ADD,
        user
    }
}

export function usersUpdate(user) {
    return {
        type: USERS_UPDATE,
        user
    }
}

export function fetchAllUsers(data) {
    return (dispatch, getState) => new Promise((resolve, reject) => {
        if (!chatClient.connected) {
            let err = new Error("Chat client not connected");
            err.name = "CHAT_CLIENT_DISCONNECTED";
            return reject(err);
        }
        chatClient.emit("fetch_all_users", data, function (err, res) {
            if (err) return reject(err);
            return resolve({ data: res.users, count: res.usersCount });
        });
    });
}
