import isAuthenticated from "../utils/isAuthenticated";

export const SET_USER = "SET_USER";
export const SET_LOGGED_OUT = "SET_LOGGED_OUT";

function _setUser(user) {
    user.createdAt = new Date(user.createdAt);
    user.updatedAt = new Date(user.updatedAt);
    return {
        type: SET_USER,
        user
    }
}

export function setLoggedOut() {
    return {
        type: SET_LOGGED_OUT
    }
}

export function setUser() {
    return dispatch => new Promise((resolve, reject) => {
        let authenticated = isAuthenticated();
        console.log("authed:", authenticated);
        if (authenticated) {
            dispatch(_setUser(authenticated));
        } else {
            dispatch(setLoggedOut());
        }
        resolve();
    });
}
