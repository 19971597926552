import React from 'react';
import NoSignageParking from './NoSignageParking';
import DemandLetter from './DemandLetter';

const TEMPLATE_LIST = {
  NoSignageParking: {
    name: 'No Signage Parking',
    template: () => <NoSignageParking />,
  },
  DemandLetter: {
    name: 'Demand Letter',
    template: () => <DemandLetter />,
  },
};

export default TEMPLATE_LIST;
